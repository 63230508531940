import Request from '~/services/request';

export default function peopleFetch(inputValue, page, doneCallback) {
  return Request.get(`/api/v1/search/people?qs=${inputValue}&page=${page}`).then(resp => {
    doneCallback (
      {
        total_count: resp.data.meta.total,
        items: resp.data.data.map( e => ({
          id: e.id,
          name: e.name,
        }))
      }
    )
  })
  .catch(err => err);
}