import Route from '~/services/route';

export const peopleRoute = {
  list:  new Route('/sys/people'),
  show:  new Route('/sys/people/:id'),
  clone: new Route('/sys/people/new/:id'),
  new:   new Route('/sys/people/new'),
  edit:  new Route('/sys/people/:id/edit'),
}

export const metadataRoute = {
  list:  new Route('/sys/metadata'),
  show:  new Route('/sys/metadata/:id'),
  clone: new Route('/sys/metadata/new/:id'),
  new:   new Route('/sys/metadata/new'),
  edit:  new Route('/sys/metadata/:id/edit'),
}

export const fieldsRoute = {
  list:  new Route('/sys/fields'),
  show:  new Route('/sys/fields/:id'),
  clone: new Route('/sys/fields/new/:id'),
  new:   new Route('/sys/fields/new'),
  edit:  new Route('/sys/fields/:id/edit'),
}

export const customRecordsRoute = {
  list:  new Route('/sys/r/:sys_key'),
  show:  new Route('/sys/r/:sys_key/:id'),
  clone: new Route('/sys/r/:sys_key/new/:id'),
  new:   new Route('/sys/r/:sys_key/new'),
  edit:  new Route('/sys/r/:sys_key/:id/edit'),
}

export const rolesRoute = {
  list:  new Route('/sys/roles'),
  show:  new Route('/sys/roles/:id'),
  clone: new Route('/sys/roles/new/:id'),
  new:   new Route('/sys/roles/new'),
  edit:  new Route('/sys/roles/:id/edit'),
}

export const usersRoute = {
  list:  new Route('/sys/users'),
  show:  new Route('/sys/users/:id'),
  clone: new Route('/sys/users/new/:id'),
  new:   new Route('/sys/users/new'),
  edit:  new Route('/sys/users/:id/edit'),
}

export const dashboardRoute = {
  self: new Route('/sys/dashboard')
}

export const httpStatusRoute = {
  notFound: new Route('/sys/404')
}
export const authRoute = {
  signIn: new Route('/sys/signin'),
  forgotPassword: new Route('/sys/forgot-password'),
  resetPassword: new Route('/sys/reset-password')
}

export const animalsRoute = {
  list:  new Route('/sys/animals'),
  show:  new Route('/sys/animals/:id'),
  clone: new Route('/sys/animals/new/:id'),
  new:   new Route('/sys/animals/new'),
  edit:  new Route('/sys/animals/:id/edit'),
}

export const dataImportsRoute = {
  list:  new Route('/sys/importacao_planilhas'),
  show:  new Route('/sys/importacao_planilhas/:id'),
  clone: new Route('/sys/importacao_planilhas/new/:id'),
  new:   new Route('/sys/importacao_planilhas/new'),
  edit:  new Route('/sys/importacao_planilhas/:id/edit'),
}

export const calcSettingsRoute = {
  self:  new Route('/sys/admin/configuracoes_de_calculo')
}

export const indicatorWeightsRoute = {
  self:  new Route('/sys/admin/indicator_weights')
}


export const rankingsRoute = {
  index: new Route('/sys/ranking/:breed'),
  holandes: new Route('/sys/ranking/holandes'),
  jersey:   new Route('/sys/ranking/jersey'),
}

export const farmsRoute = {
  list:  new Route('/sys/fazendas'),
  show:  new Route('/sys/fazendas/:id'),
  clone: new Route('/sys/fazendas/new/:id'),
  new:   new Route('/sys/fazendas/new'),
  edit:  new Route('/sys/fazendas/:id/edit'),
}