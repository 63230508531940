import React from 'react';
import { Switch } from 'react-router-dom';

import { animalsRoute } from '~/routes'
import RouteWrapper from '~/routes/RouteWrapper';
import List from './List';
import Show from './Show';
import Edit from './Edit';
import New  from './New';

export default function AnimalsRoutesComponent() {
  return (
    <Switch>
      <RouteWrapper
        exact
        isPrivate
        path={animalsRoute.new.path}
        component={New}
        permissionKeyCode="Animal"
        permission="add"
      />
      <RouteWrapper
        exact
        isPrivate
        path={animalsRoute.clone.path}
        component={New}
        permissionKeyCode="Animal"
        permission="add"
      />
      <RouteWrapper
        exact
        isPrivate
        path={animalsRoute.show.path}
        component={Show}
        permissionKeyCode="Animal"
        permission="see"
      />
      <RouteWrapper
        exact
        isPrivate
        path={animalsRoute.edit.path}
        component={Edit}
        permissionKeyCode="Animal"
        permission="edit"
      />

      <RouteWrapper
        isPrivate
        redirectIfNotExact
        path={animalsRoute.list.path}
        component={List}
        permissionKeyCode="Animal"
        permission="see"
      />
    </Switch>
  );
}
