import React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import DescriptionIcon from '@material-ui/icons/Description';
import AdministrationIcon from '@material-ui/icons/AttachMoney';
import PermissionIcon from '@material-ui/icons/Lock';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import UserIcon from '@material-ui/icons/Person';
import StorageIcon from '@material-ui/icons/Storage';
import InputIcon from '@material-ui/icons/Input';
import { GiBullHorns } from "@react-icons/all-files/gi/GiBullHorns";
import { GiPodium } from "@react-icons/all-files/gi/GiPodium";
import { GiDna1 } from "@react-icons/all-files/gi/GiDna1";
import { HiChartBar } from "@react-icons/all-files/hi/HiChartBar";

import PublishIcon from '@material-ui/icons/Publish';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';

import {
  animalsRoute,
  farmsRoute,
  metadataRoute,
  fieldsRoute,
  rolesRoute,
  usersRoute,
  dataImportsRoute,
  dashboardRoute,
  calcSettingsRoute,
  indicatorWeightsRoute,
  rankingsRoute
} from '~/routes'

export const drawerMenuAvatar = {
  name: 'Halley Specter',
  roleName: 'Admin',
  url: 'https://66.media.tumblr.com/3e3f627161fb69b617504b320b57d480/tumblr_pojtnodtMz1rfd9dz_640.jpg',
  onClick: () => {},
};

// eslint-disable-next-line
const MakeIcon = (Icon, size) => ({htmlColor, ...props}) => <Icon size={size} {...props} />

const drawerMenuItems = [
  {
    name: 'Início',
    icon: DashboardIcon,
    to: dashboardRoute.self.path
  },
  {
    name: 'Ranking',
    icon: MakeIcon(GiPodium, '24px'),
    collapse: [
      {
        keyCode: 'Ranking',
        name: 'Holandês',
        icon: MakeIcon(HiChartBar, '24px'),
        to: rankingsRoute.holandes.path
      }, {
        keyCode: 'Ranking',
        name: 'Jersey',
        icon: MakeIcon(HiChartBar, '24px'),
        to: rankingsRoute.jersey.path
      }
    ]
  },
  {
    name: 'Fazendas',
    icon: HomeWorkIcon,
    keyCode: 'Farm',
    to: farmsRoute.list.path
  },
  {
    name: 'Animais',
    icon: MakeIcon(GiBullHorns, '24px'),
    keyCode: 'Animal',
    to: animalsRoute.list.path
  },
  {
    name: 'Importação de Planilhas',
    icon: PublishIcon,
    keyCode: 'DataImport',
    to: dataImportsRoute.list.path
  },
  {
    name: 'Dinareading',
    icon: MakeIcon(GiDna1, '24px'),
    to: 'http://dinareading.com.br',
    external: true
  },
  {
    name: 'Administração',
    icon: AdministrationIcon,
    collapse: [
      {
        name: 'Permissões',
        icon: PermissionIcon,
        keyCode: 'Permission',
        to: rolesRoute.list.path
      }, {
        name: 'Usuários',
        icon: UserIcon,
        keyCode: 'User',
        to: usersRoute.list.path
      },
      {
        name: 'Conf. Percentuais',
        icon: SettingsApplicationsIcon,
        keyCode: 'ONLY-ADMIN',
        to: calcSettingsRoute.self.path
      },
      {
        name: 'Conf. Pontuações',
        icon: SettingsApplicationsIcon,
        keyCode: 'ONLY-ADMIN',
        to: indicatorWeightsRoute.self.path
      },
    ]
  },
  {
    name: 'Master',
    icon: DragIndicatorIcon,
    collapse: [
      {
        name: 'Metadados',
        icon: StorageIcon,
        keyCode: 'Metadata',
        onlyMaster: true,
        to: metadataRoute.list.path
      },
      {
        name: 'Campos',
        icon: InputIcon,
        keyCode: 'Field',
        onlyMaster: true,
        to: fieldsRoute.list.path
      },{
        onlyMaster: true,
        name: 'Examples',
        icon: DescriptionIcon,
        keyCode: 'Example',
        to: '/sys/components-example'
      }
    ]
}];

export default drawerMenuItems