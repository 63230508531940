import React from 'react';

import cellere from '~/assets/images/cellere-black.png'
import dinareading from '~/assets/images/dinareading.png'
import dnaGenetica from '~/assets/images/dna-genetica.png'

import {
  Header, Image, Content
} from './styles'

function HeaderPrint() {
  return (
    <Header className="only-print">
      <Content>
        <Image variant="rounded" style={{gridArea: 'img-dnaGenetica'}} src={dnaGenetica} />
        <Image variant="rounded" style={{gridArea: 'img-cellere'}} src={cellere} />
        <Image variant="rounded" style={{gridArea: 'img-dinareading'}} src={dinareading} />
      </Content>
    </Header>
  );
}

export default React.memo(HeaderPrint)