import React, { createContext, useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';

const PermissionsContext = createContext({
  permissions: [],
});

export const PermissionsProvider = ({ children }) => {
  const { permissions = {}, access_type } = useSelector(state => state.userProfile.data);

  const can = useCallback((keyCode, permission, onlyMaster=false) => {
    if(onlyMaster){ return access_type === 'master' }

    if(!keyCode){ return true }

    if(['admin','master'].includes(access_type)){ return true }

    if (permission === 'see') return !!permissions[keyCode];

    return !!permissions[keyCode]?.[permission];
  }, [permissions]);

  return (
    <PermissionsContext.Provider value={{ can }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => {
  const context = useContext(PermissionsContext);

  if (!context) {
    throw new Error('usePermissions must be used within a PermissionsProvider');
  }

  return context;
};

export default PermissionsContext;
