import React, { useCallback } from 'react';

import { dataImportsRoute } from '~/routes'
import CrudLayoutList from '~/containers/CrudLayoutList';
import dataImportSchema from '~/records/schema/dataImportSchema';
import { useDispatch, connect } from 'react-redux';
import { fetchRecords, destroyRecord } from '~/store/modules/dataImports';

function List({isLoading, rows, total}) {
  const dispatch = useDispatch();

  const handleFetch = useCallback(
    async (params, requestSource) => {
      dispatch( fetchRecords(`${params}&include=user`, requestSource) )
    },
    [],
  )

  const onDelete = useCallback(
    async (row) => {
      dispatch( destroyRecord(row.id) )
    },
    []
  )

  return (
    <CrudLayoutList
      breadcrumbs={[{text: 'Planilhas importadas' }]}
      permissionKeyCode="DataImport"
      routes={{
        newPath:  ()  =>  dataImportsRoute.new.build(),
        showPath: (id) => dataImportsRoute.show.build({id})
      }}
      tableProps={{
        isLoading,
        rows,
        total,
        handleFetch,
        onDelete,
        schema: dataImportSchema,
        actions: ['show', 'delete'],
        permissionKeyCode: 'DataImport',
        filterProps: {
          columns: dataImportSchema.filterColumns
        },
        columnsOverride: [
          {
            name: 'user',
            formatterComponent: (_, row) => (
              <div>{ row.user?.name }</div>
            )
          },
          {
            name: 'farm',
            formatterComponent: (_, row) => (
              <div>{ row.farm?.name }</div>
            )
          }
        ],
      }}
    />
  );
}

const mapStateToProps = state => ({
  isLoading: state.dataImports.listIsLoading,
  rows: state.dataImports.data,
  total: state.dataImports.totalData,
});

export default connect(mapStateToProps)(List);