import React, { useState, useImperativeHandle, forwardRef, memo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const callbacks = {
  cancel: () => {},
  confirm: () => {}
};

function ConfirmationDialogComponent(
  {
    title,
    description,
    cancelText,
    confirmText
  },
  dialogRef
) {
  const [open, setOpen] = useState(false);

  useImperativeHandle(dialogRef, () => ({
    open: () => setOpen(true),
    close: () => setOpen(false),
    onCancel: (callback) => {
      callbacks.cancel = callback;
    },
    onConfirm: (callback) => {
      callbacks.confirm = callback;
    }
  }));

  function handleCancel() {
    setOpen(false);
    callbacks.cancel();
  }

  function handleConfirm() {
    setOpen(false);
    callbacks.confirm();
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCancel}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {cancelText}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const ConfirmationDialog = forwardRef(ConfirmationDialogComponent);

ConfirmationDialog.defaultProps = {
  title: '',
  description: '',
  cancelText: 'Cancelar',
  confirmText: 'Confirmar'
}

ConfirmationDialog.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string
}

export default memo(ConfirmationDialog);
