import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { findRecord, destroyRecord, clearState } from '~/store/modules/farms';
import { farmsRoute } from '~/routes'

import LayoutShow from '~/components/LayoutShow';
import InputShow from '~/components/Input/InputShow';

function Show({ farm, isLoading }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const onDelete = useCallback(
    () => {
      dispatch( destroyRecord(id) )
    },
    [id]
  )

  useEffect(() => {
    dispatch(findRecord(id));

    return () => dispatch(clearState());
  }, []);

  return (
    <LayoutShow
      breadcrumbs={[
        { path: farmsRoute.list.path, text: 'Fazendas' },
        { text: 'Exibindo Fazenda' },
      ]}
      loading={isLoading}
      crudActionsProps={{
        onDelete,
        routeNewRegister:   farmsRoute.new.build(),
        routeCloneRegister: farmsRoute.clone.build({ id }),
        routeEditRegister:  farmsRoute.edit.build({ id }),
        permissionKeyCode:  'Farm'
      }}
    >
      <Card>
        <CardContent>
          <InputShow
            label="Nome"
            value={farm?.name}
          />
        </CardContent>
      </Card>
    </LayoutShow>
  );
}

const mapStateToProps = state => ({
  isLoading: state.farms.isLoading,
  farm: state.farms.current,
});

export default connect(mapStateToProps)(Show);