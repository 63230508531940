import React, { useEffect , useState, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';

import Button from '~/components/Button';
import InputControl from '~/components/Input/InputControl';
import InputSelect     from '~/components/Input/InputSelect';

const useStyles = makeStyles({
  root: {
    width: 350,
    padding: '10px 16px 16px 16px'
  }
});

export default function RankingFilter({isOpen, allBulls, filtredBulls, onClose, onApply}) {
  const classes = useStyles();
  const [bulls, setBulls] = useState([])
  const [farms, setFarms] = useState([])

  const allFarms = useMemo(
    () => {
      const farmsObj = {}
      if (!Array.isArray(allBulls)) {
        return []
      }

      allBulls.forEach((bull) => farmsObj[bull.farm_id] = bull.farm )

      return Object.values(farmsObj)
    },
    [allBulls]
  )

  useEffect(()=>{
    setBulls(
      allBulls.filter((bull) => filtredBulls.includes(bull.id))
    )
  }, [filtredBulls, allBulls, isOpen])

  const handleChangeBulls = useCallback( ({bullsInput}) => {
    setBulls(bullsInput)
  }, [allBulls, setBulls, bulls])

  const handleChangeFarms = useCallback( ({farmsInput}) => {
    setFarms(farmsInput)
  }, [allFarms, setFarms, farms])

  const onApplyFilters = useCallback( () => {
    onApply({
      bulls: bulls?.map( ({id}) => id ) || [],
      farms: farms?.map( ({id}) => id ) || []
    })
  }, [onApply, bulls, farms])

  return (
    <Drawer anchor={'right'} open={isOpen} onClose={ onClose }>
      <Box
        className={ clsx(classes.root) }
        role="presentation"
      >
        <Box>
          <InputControl
            value={bulls}
            name={'bullsInput'}
            label={'Filtrar touros'}
            inputComponent={InputSelect}
            onChange={handleChangeBulls}
            InputProps={{
              options: allBulls,
              isMulti: true,
              closeMenuOnSelect: false
            }}
          />
        </Box>

        <Box>
          <InputControl
            value={farms}
            name={'farmsInput'}
            label={'Filtrar Fazendas'}
            inputComponent={InputSelect}
            onChange={handleChangeFarms}
            InputProps={{
              options: allFarms,
              isMulti: true,
              closeMenuOnSelect: false
            }}
          />
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Button variant="text" color="error" onClick={()=>onClose()}>Cancelar</Button>
          <Button variant="text" color="green" onClick={onApplyFilters}>Aplicar</Button>
        </Box>

      </Box>
    </Drawer>
  );
}
