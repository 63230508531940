import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import CrudEdit from '~/containers/CrudEdit';

import { indicatorWeightsApi } from '~/routes/api';
import Request from '~/services/request';

import InputControl from '~/components/Input/InputControl';
import InputNumber from '~/components/Input/InputNumber';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';

const attrNames = {
  dutch_udder_posterior_ligament_weight:        'Ligamento Post. Úbere',
  dutch_udder_anterior_ligament_weight:         'Ligamento Ant. Úbere',
  dutch_udder_depth_weight:                     'Profundidade Úbere',
  dutch_udder_width_weight:                     'Largura Úbere',
  dutch_udder_height_weight:                    'Altura Úbere',
  dutch_udder_posterior_tits_weight:            'Colocação Tetos Posteriores',
  dutch_udder_anterior_tits_weight:             'Colocação Tetos Anteriores',
  dutch_solid_compound_fat_vol_weight:          'Volume de gordura',
  dutch_solid_compound_protein_vol_weight:      'Volume de proteína',
  dutch_solid_compound_fat_percent_weight:      'Percentual de gordura',
  dutch_solid_compound_protein_percent_weight:  'Percentual de proteína',
  dutch_solid_compound_somatic_cells_weight:    'Células Somáticas',
  dutch_fertility_idx_ccr_weight:               'CCR (Taxa de Prenhes Vacas)',
  dutch_fertility_idx_hcr_weight:               'HCR (Taxa de Prenhes Novilhas)',
  dutch_fertility_idx_dpr_weight:               'DPR (Taxa de Prenhes Filhas)',
  dutch_milk_vol_weight:                        'Volume de leite',
  dutch_composite_paws_legs_side_view_weight:    'Pernas Vista Lateral',
  dutch_composite_paws_legs_behind_view_weight:  'Pernas Vistas por Trás',
  dutch_composite_paws_legs_hull_angle_weight:   'Ângulo de Cascos',
  dutch_body_compound_stature_weight:            'Estatura',
  dutch_body_compound_strength_weight:           'Força',
  dutch_body_compound_depth_weight:              'Prof. Corporal',
  dutch_body_compound_milk_weight:               'Caracter Leiteiro',
  dutch_body_compound_croup_angle_weight:        'Ângulo de Garupa',
  dutch_body_compound_croup_width_weight:        'Largura de Garupa',

  jersey_udder_posterior_ligament_weight:       'Ligamento Post. Úbere',
  jersey_udder_anterior_ligament_weight:        'Ligamento Ant. Úbere',
  jersey_udder_depth_weight:                    'Profundidade Úbere',
  jersey_udder_width_weight:                    'Largura Úbere',
  jersey_udder_height_weight:                   'Altura Úbere',
  jersey_udder_posterior_tits_weight:            'Colocação Tetos Posteriores',
  jersey_udder_anterior_tits_weight:            'Colocação Tetos Anteriores',
  jersey_solid_compound_fat_vol_weight:         'Volume de gordura',
  jersey_solid_compound_protein_vol_weight:     'Volume de proteína',
  jersey_solid_compound_fat_percent_weight:     'Percentual de gordura',
  jersey_solid_compound_protein_percent_weight: 'Percentual de proteína',
  jersey_solid_compound_somatic_cells_weight:   'Células Somáticas',
  jersey_fertility_idx_ccr_weight:              'CCR (Taxa de Prenhes Vacas)',
  jersey_fertility_idx_hcr_weight:              'HCR (Taxa de Prenhes Novilhas)',
  jersey_fertility_idx_dpr_weight:              'DPR (Taxa de Prenhes Filhas)',
  jersey_milk_vol_weight:                       'Volume de leite',
  jersey_composite_paws_legs_side_view_weight:   'Pernas Vista Lateral',
  jersey_composite_paws_legs_behind_view_weight: 'Pernas Vistas por Trás',
  jersey_composite_paws_legs_hull_angle_weight:  'Ângulo de Cascos',
  jersey_body_compound_stature_weight:           'Estatura',
  jersey_body_compound_strength_weight:          'Força',
  jersey_body_compound_depth_weight:             'Prof. Corporal',
  jersey_body_compound_milk_weight:              'Caracter Leiteiro',
  jersey_body_compound_croup_angle_weight:       'Ângulo de Garupa',
  jersey_body_compound_croup_width_weight:       'Largura de Garupa',
}

function attrName(attr){ return attrNames[attr] }

const InputInt = ({attr, onChange, record}) =>
  <InputControl
    required
    name={attr}
    label={attrName( attr )}
    value={record ? record[attr] : 0}
    inputComponent={InputNumber}
    onChange={onChange}
    InputProps={{ precision: 0 }}
  />

export default function CalcSettings() {
  const [record, setRecord] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Request.get(indicatorWeightsApi.show.build()).then( resp => {
      setRecord( resp.data.data )
      setIsLoading(false)
    })
  }, [])

  const onSubmit = useCallback( () => {
    setIsLoading(true)
    Request.put(indicatorWeightsApi.save.build(), {
      data: {
        attributes: record
      }
    }).then( resp => {
      setRecord( resp.data.data )
      setIsLoading(false)
      toast.success('Dados salvos com sucesso!');
    }).catch( e => {
      console.error(e);
      toast.error('Falha ao salvar!');
    } )
  }, [record]);

  const onChange = useCallback(attrs => {
    setRecord( {...record, ...attrs} );
  }, [record, setRecord]);

  return (
    <CrudEdit
      isLoading={isLoading}
      onSubmit={onSubmit}
      breadcrumbs={[{text: 'Configuração de pontuações'}]}
      footerActionsProps={{isLoading, showSaveAndContinue: false}}
    >
      <Card>
        <CardContent>
          <Typography variant="subtitle1">HOLANDÊS</Typography>

          <fieldset>
            <legend><Typography variant="subtitle1">Composto de Úbere</Typography></legend>
            <GridContainer>
              <Col sm={4} md={3} >
                <InputInt attr="dutch_udder_posterior_ligament_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={4} md={3} >
                <InputInt attr="dutch_udder_anterior_ligament_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={4} md={3} >
                <InputInt attr="dutch_udder_depth_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={4} md={3} >
                <InputInt attr="dutch_udder_width_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={4} md={4} >
                <InputInt attr="dutch_udder_height_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={4} md={4} >
                <InputInt attr="dutch_udder_anterior_tits_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={4} md={4} >
                <InputInt attr="dutch_udder_posterior_tits_weight" {...{record, onChange}}/>
              </Col>
            </GridContainer>
          </fieldset>

          <fieldset>
            <legend><Typography variant="subtitle1">Composto de Sólidos</Typography></legend>
            <GridContainer>
              <Col sm={4} md={2} >
                <InputInt attr="dutch_solid_compound_fat_vol_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={4} md={2} >
                <InputInt attr="dutch_solid_compound_protein_vol_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={4} md={2} >
                <InputInt attr="dutch_solid_compound_fat_percent_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={6} md={3} >
                <InputInt attr="dutch_solid_compound_protein_percent_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={6} md={3} >
                <InputInt attr="dutch_solid_compound_somatic_cells_weight" {...{record, onChange}}/>
              </Col>
            </GridContainer>
          </fieldset>

          <fieldset>
            <legend><Typography variant="subtitle1">Composto de Saúde/Fertilidade</Typography></legend>
            <GridContainer>
              <Col sm={4} >
                <InputInt attr="dutch_fertility_idx_ccr_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={4} >
                <InputInt attr="dutch_fertility_idx_hcr_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={4} >
                <InputInt attr="dutch_fertility_idx_dpr_weight" {...{record, onChange}}/>
              </Col>
            </GridContainer>
          </fieldset>

          <fieldset>
            <legend><Typography variant="subtitle1">Produção Leiteira</Typography></legend>
            <GridContainer>
              <Col>
                <InputInt attr="dutch_milk_vol_weight" {...{record, onChange}}/>
              </Col>
            </GridContainer>
          </fieldset>

          <fieldset>
            <legend><Typography variant="subtitle1">Composto de Patas e Pernas</Typography></legend>
            <GridContainer>
              <Col sm={4}>
                <InputInt attr="dutch_composite_paws_legs_side_view_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={4}>
                <InputInt attr="dutch_composite_paws_legs_behind_view_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={4}>
                <InputInt attr="dutch_composite_paws_legs_hull_angle_weight" {...{record, onChange}}/>
              </Col>
            </GridContainer>
          </fieldset>

          <fieldset>
            <legend><Typography variant="subtitle1">Composto Corporal</Typography></legend>
            <GridContainer>
              <Col sm={6} md={2} >
                <InputInt attr="dutch_body_compound_stature_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={6} md={2} >
                <InputInt attr="dutch_body_compound_strength_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={6} md={2} >
                <InputInt attr="dutch_body_compound_depth_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={6} md={2} >
                <InputInt attr="dutch_body_compound_milk_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={6} md={2} >
                <InputInt attr="dutch_body_compound_croup_angle_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={6} md={2} >
                <InputInt attr="dutch_body_compound_croup_width_weight" {...{record, onChange}}/>
              </Col>
            </GridContainer>
          </fieldset>
        </CardContent>
      </Card>
      <br/>
      <Card>
        <CardContent>
          <Typography variant="subtitle1">JERSEY</Typography>
          <fieldset>
            <legend><Typography variant="subtitle1">Composto de Úbere</Typography></legend>
            <GridContainer>
              <Col sm={4} md={3} >
                <InputInt attr="jersey_udder_posterior_ligament_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={4} md={3} >
                <InputInt attr="jersey_udder_anterior_ligament_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={4} md={3} >
                <InputInt attr="jersey_udder_depth_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={4} md={3} >
                <InputInt attr="jersey_udder_width_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={4} md={4} >
                <InputInt attr="jersey_udder_height_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={4} md={4} >
                <InputInt attr="jersey_udder_anterior_tits_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={4} md={4} >
                <InputInt attr="jersey_udder_posterior_tits_weight" {...{record, onChange}}/>
              </Col>
            </GridContainer>
          </fieldset>

          <fieldset>
            <legend><Typography variant="subtitle1">Composto de Sólidos</Typography></legend>
            <GridContainer>
              <Col sm={4} md={2} >
                <InputInt attr="jersey_solid_compound_fat_vol_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={4} md={2} >
                <InputInt attr="jersey_solid_compound_protein_vol_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={4} md={2} >
                <InputInt attr="jersey_solid_compound_fat_percent_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={6} md={3} >
                <InputInt attr="jersey_solid_compound_protein_percent_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={6} md={3} >
                <InputInt attr="jersey_solid_compound_somatic_cells_weight" {...{record, onChange}}/>
              </Col>
            </GridContainer>
          </fieldset>

          <fieldset>
            <legend><Typography variant="subtitle1">Composto de Saúde/Fertilidade</Typography></legend>
            <GridContainer>
              <Col sm={4} >
                <InputInt attr="jersey_fertility_idx_ccr_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={4} >
                <InputInt attr="jersey_fertility_idx_hcr_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={4} >
                <InputInt attr="jersey_fertility_idx_dpr_weight" {...{record, onChange}}/>
              </Col>
            </GridContainer>
          </fieldset>

          <fieldset>
            <legend><Typography variant="subtitle1">Produção Leiteira</Typography></legend>
            <GridContainer>
              <Col>
                <InputInt attr="jersey_milk_vol_weight" {...{record, onChange}}/>
              </Col>
            </GridContainer>
          </fieldset>

          <fieldset>
            <legend><Typography variant="subtitle1">Composto de Patas e Pernas</Typography></legend>
            <GridContainer>
              <Col sm={4}>
                <InputInt attr="jersey_composite_paws_legs_side_view_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={4}>
                <InputInt attr="jersey_composite_paws_legs_behind_view_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={4}>
                <InputInt attr="jersey_composite_paws_legs_hull_angle_weight" {...{record, onChange}}/>
              </Col>
            </GridContainer>
          </fieldset>

          <fieldset>
            <legend><Typography variant="subtitle1">Composto Corporal</Typography></legend>
            <GridContainer>
              <Col sm={6} md={2} >
                <InputInt attr="jersey_body_compound_stature_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={6} md={2} >
                <InputInt attr="jersey_body_compound_strength_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={6} md={2} >
                <InputInt attr="jersey_body_compound_depth_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={6} md={2} >
                <InputInt attr="jersey_body_compound_milk_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={6} md={2} >
                <InputInt attr="jersey_body_compound_croup_angle_weight" {...{record, onChange}}/>
              </Col>
              <Col sm={6} md={2} >
                <InputInt attr="jersey_body_compound_croup_width_weight" {...{record, onChange}}/>
              </Col>
            </GridContainer>
          </fieldset>
        </CardContent>
      </Card>
    </CrudEdit>
  );
}