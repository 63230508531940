import React from 'react';
import { Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import 'react-toastify/dist/ReactToastify.css';
import 'react-circular-progressbar/dist/styles.css';
import {ToastContainer} from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import ReduxProvider from '~/providers/ReduxProvider';
import ThemeProvider from '~/providers/ThemeProvider';

import Main from './Main';
import history from './services/history';
import { persistor } from '~/store';

function App() {
  return (
    <ReduxProvider>
      <PersistGate persistor={persistor}>
        <CssBaseline />
        <ThemeProvider>
          <Router history={history}>
            <Main />
            <ToastContainer autoClose={5000} />
          </Router>
        </ThemeProvider>
      </PersistGate>
    </ReduxProvider>
  );
}

export default App;
