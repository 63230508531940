import React     from 'react';
import PropTypes from 'prop-types';

// react-select component
// import AsyncSelect from 'react-select/lib/Async';
// import CreatableSelect from 'react-select/lib/Creatable';
// import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
// --------------------------------------------------------------

// material-ui components
import withStyles    from '@material-ui/core/styles/withStyles';
// --------------------------------------------------------------

// material-ui icons
import AddCircleIcon from '@material-ui/icons/AddCircle';
// --------------------------------------------------------------

// self components
import InputSelect from '../InputSelect';
// --------------------------------------------------------------

import isPresent from '~/utils/isPresent';
import Request from '~/services/request';

const styleLabelNew = () => ({
  link: {
    display:    'flex',
    alignItems: 'center',
    color:      '#2e962e',
    cursor: 'pointer'
  },
  label: {
    marginLeft: '5px',
    flex: 1
  }
});
const CreateLabelComponentBase = ({classes, children}) => {
  return (
    <div role="link" tabIndex="0" className={classes.link}>
      <AddCircleIcon />
      <span className={classes.label} >{`Criar "${children}" como novo`}</span>
    </div>
  );
};
const CreateLabelComponent = withStyles(styleLabelNew)(CreateLabelComponentBase);

function formatCreateLabel(label){
  return(
    <CreateLabelComponent>
      {label}
    </CreateLabelComponent>
  );
}

function isValidNewOption(inputValue /*, selectValue, selectOptions*/){
  return isPresent(inputValue);
}

class IntegrationSelectAsync extends React.Component {
  static displayName = 'IntegrationSelectAsync';
  static propTypes = {
    // classes:         PropTypes.object.isRequired,
    selectComponent:       PropTypes.func.isRequired,
    handleFetch:           PropTypes.func,   // A method to make a custom fetch
    fetchUrl:              PropTypes.string, // Request URL to component make an internal fetch
    createEnabled:         PropTypes.bool.isRequired,
    isValidNewOption:      PropTypes.func.isRequired,
    onCreateCallback:      PropTypes.func,
  };

  static defaultProps = {
    selectComponent:       AsyncCreatableSelect,
    createEnabled:         true,
    isValidNewOption:      isValidNewOption
  };

  searchTimeout = null;
  childRef = null;

  state = {
    nextPage:     1,
    inputValue:   '',
    options:      [],
    isCreating:   false,
    isLoading:    false,
    menuIsOpen:   false,
    isPaginate:   false,
    forceLoadOptions: false,
  };

  componentDidMount(){
    const { forceLoadOptions } = this.props;
    if( forceLoadOptions ){
      this.loadOptionsOnMenuOpen().then()
    }
  }

  fetchOptions = (inputValue, page, doneCallback) => {
    const { handleFetch, fetchUrl } = this.props;

    if ( isPresent(fetchUrl) ) {
      this.handleFindData(inputValue, page, doneCallback);
    } else {
      handleFetch(inputValue, page, doneCallback);
    }
  };

  handleFindData(inputValue, page, doneCallback) {
    const { fetchUrl } = this.props;
    const extraParams = `qs=${inputValue}&page=${page}`;
    const path = fetchUrl.includes('?')
      ? `${fetchUrl}&${extraParams}`
      : `${fetchUrl}?${extraParams}`;

    Request.get(path).then(resp => {
			doneCallback(
				{
					total_count: resp.data.meta.total,
					items: resp.data.data.map( e => ({
						id: e.id,
						name: e.attributes.name
					}))
				}
			)
    })
    .catch(err => err);
  }

  loadOptionsOnMenuOpen = () =>{
    this.setState({isLoading: true});

    return new Promise(resolve => {
      clearTimeout( this.searchTimeout );

      this.searchTimeout = setTimeout(() => {
        const { inputValue } = this.state;

        this.fetchOptions( inputValue, 1, (response) => {
          const total = response.total_count || response.items.length;

          this.setState({
            isLoading: false,
            nextPage:  2,
            isPaginate: total > response.items.length,
            options:   response.items
          });
          resolve();
        } );
      }, 1);
    });
  };

  onMenuOpen = () => {
    const { isLoading, menuIsOpen } = this.state;
    if ( !menuIsOpen ){

      this.setState({menuIsOpen: true});
      if( !isLoading ){
        this.loadOptionsOnMenuOpen();
      }
    }
  };

  onMenuClose = () => {
    this.setState({
      inputValue: '',
      menuIsOpen: false,
      isLoading:  false,
      nextPage:   1
    });
  };

  handlePagination = () => {
    const { nextPage, options, inputValue } = this.state;

    return new Promise( () => {
      this.fetchOptions(inputValue, nextPage, (response) => {
        const total = response.total_count || response.items.length;
        const newOptions = [...options, ...response.items];
        const select = this.childRef.selectRef.current;

        this.setState({
          isLoading: false,
          nextPage:  nextPage + 1,
          isPaginate: total > newOptions.length,
          options:   newOptions
        });

        select.setState({loadedOptions: this.childRef.handlerOptions( newOptions ) });
      } );
    });
  };

  // Chama essa função quando digitar algo
  onLoadOptions = (inputValue) => {
    clearTimeout( this.searchTimeout );

    this.setState({
      inputValue: inputValue,
      isLoading: true,
      nextPage:  1,
    });

    return new Promise(resolve => {
      this.searchTimeout = setTimeout(() => {
        this.fetchOptions( inputValue, 1, this.onDoneLoadOptions(resolve) );
      }, 500);
    });
  };

  onDoneLoadOptions = (resolve) => (response) => {
    const { nextPage } = this.state;
    const newOptions = response.items;
    const total = response.total_count || response.items.length;

    this.setState({
      isLoading: false,
      nextPage:  nextPage + 1,
      options:   newOptions,
      isPaginate: total > newOptions.length,
    });
    return resolve( this.childRef.handlerOptions( newOptions ) );
  };

  onInputChange = (inputValue, {action}) => {
    if( action === 'input-change' ){
      this.setState({inputValue, nextPage: 1, isLoading: true, options: null});
    }
  };

  // --------------------------------------------------------------------------------------------
  // ------------------- START CREATE METHODS  --------------------------------------------------
  // --------------------------------------------------------------------------------------------

  isValidNewOption = (inputValue, selectValue, selectOptions) => {
    const { createEnabled, isValidNewOption } = this.props;

    return createEnabled && isValidNewOption(inputValue, selectValue, selectOptions);
  };

  onCreateOptionInternal = (inputValue) => {
    const { onCreateCallback } = this.props;

    this.setState({isCreating: true});

    if( isPresent(onCreateCallback) ){
      new Promise((resolve) => {
        onCreateCallback(
          inputValue,
          this.childRef,
          resolve
        );
      }).then(this.onCreateDone);
    }
  };

  onCreateDone = () => {
    this.setState({isCreating: false});
  }

  // ------------------------------------------------------------------------------------------
  // ------------------- END CREATE METHODS  --------------------------------------------------
  // ------------------------------------------------------------------------------------------

  render() {
    // eslint-disable-next-line
    const {url, isValidNewOption, disabled, ...rest} = this.props;
    const {options, isCreating, isLoading, isPaginate } = this.state;

    return (
      <InputSelect
        isDisabled={isCreating || disabled}
        onCreateOption={ this.onCreateOptionInternal }
        setRef={ (c) => this.childRef = c }
        isLoading={isCreating || isLoading}
        onInputChange={this.onInputChange}
        captureMenuScroll={isPaginate}
        onMenuScrollToBottom={this.handlePagination}
        onMenuOpen={this.onMenuOpen}
        onMenuClose={this.onMenuClose}
        isValidNewOption={this.isValidNewOption}
        defaultOptions={options}
        loadOptions={this.onLoadOptions}
        formatCreateLabel={formatCreateLabel}
        {...rest}
      />
    );
  }
}

export default IntegrationSelectAsync;
