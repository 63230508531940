import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { findUserProfileRequest } from '~/store/modules/userProfile';
import getMe from '~/utils/getMe'
import AnimatedLoadingFull from '~/components/AnimatedLoadingFull';

import HeaderPrint from '~/containers/HeaderPrint'
import FooterPrint from '~/containers/FooterPrint'

import PublicRoutes  from './publicRoute';
import PrivateRoutes from './privateRoute';

const MainComponent = styled.main`
  display: flex;
`;

function Main({ signed, loading }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (signed) {
      const registerId = getMe().id;
      dispatch(findUserProfileRequest(registerId));
    }
  }, [signed]);

  if (!signed) {
    return (
      <MainComponent>
        <PublicRoutes />
      </MainComponent>
    );
  }

  return loading ? (
      <AnimatedLoadingFull />
    ) : (
      <>
        <HeaderPrint />
        <MainComponent>
          <PrivateRoutes />
        </MainComponent>
        <FooterPrint />
      </>
    );
}

const mapStateToProps = state => ({
  signed: state.auth.signed,
  loading: state.userProfile.searchingRecord,
});

export default connect(mapStateToProps)(Main);
