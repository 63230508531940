import React from 'react';
import { Switch } from 'react-router-dom';

import { rankingsRoute } from '~/routes'
import RouteWrapper from '~/routes/RouteWrapper';
import Holandes from './Holandes';
import Jersey from './Jersey';

export default function DashboardRoute() {
  return (
    <Switch>
      <RouteWrapper
        exact
        isPrivate
        path={rankingsRoute.holandes.path}
        component={Holandes}
      />
      <RouteWrapper
        exact
        isPrivate
        path={rankingsRoute.jersey.path}
        component={Jersey}
      />
    </Switch>
  );
}