import React from 'react';

import Typography from '@material-ui/core/Typography';
import InstagramIcon from '@material-ui/icons/Instagram';
import LanguageIcon from '@material-ui/icons/Language';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import RoomIcon from '@material-ui/icons/Room';

import {
  Footer, Container, Content, Text, Icon, Box
} from './styles'

function FooterPrint() {
  return (
    <Footer className="only-print">
      <Typography align="center" variant="subtitle1" component="h1">
        <strong>DNA GENÉTICA DO BRASIL</strong>
      </Typography>
      <Container>
        <Content>
          <Box>
            <Icon><RoomIcon /></Icon>
            <Text>
              Linha Bela Vista, 24, Bloco A, Rural, CEP: 89843-000 - Águas Frias, SC
            </Text>
          </Box>
          <Box>
            <Icon><PhoneIcon /></Icon>
            (49) 3346-2976
          </Box>
          <Box>
            <Icon><MailOutlineIcon /></Icon>
            comercial@dnageneticadobrasil.com.br
          </Box>
        </Content>
        <Content style={{textAlign: 'right', marginLeft: 'auto'}}>
          <Box>
            <Icon><LanguageIcon /></Icon>
            https://dnageneticadobrasil.com.br
          </Box>
          <Box>
            <Icon><InstagramIcon /></Icon>
            @dnageneticadobrasil
          </Box>
        </Content>
      </Container>
    </Footer>
  );
}

export default React.memo(FooterPrint)