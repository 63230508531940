import RecordBase from './Base';
import farmSchema from './schema/farmSchema';

export default class AnimalRecord extends RecordBase {
  static defaultAttributes = farmSchema.defaultAttributes;
  static _attributeNames = null;
  static get attributeNames() {
    if(!this._attributeNames){
      this._attributeNames = farmSchema.attributeNames;
    }
    return this._attributeNames;
  }

  toParams(extra={}) {
    return {
      data:{
        attributes: { ...this.toObject(), ...extra }
      }
    };
  }
}