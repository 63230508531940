import React, { useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';

import { findRecord, destroyRecord, clearState } from '~/store/modules/dataImports';
import { dataImportsRoute } from '~/routes'

import LayoutShow from '~/components/LayoutShow';
import InputShow from '~/components/Input/InputShow';

function Show({ dataImport, isLoading }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const file = useMemo(
    () => dataImport?.spreadsheet,
    [dataImport?.spreadsheet || 0]
  )

  const onDelete = useCallback(
    () => {
      dispatch( destroyRecord(id) )
    },
    [id]
  )

  useEffect(() => {
    dispatch(findRecord(id, {include: 'user-spreadsheet-farm'}));

    return () => dispatch(clearState());
  }, []);

  return (
    <LayoutShow
      breadcrumbs={[
        { path: dataImportsRoute.list.path, text: 'Planilhas importadas' },
        { text: 'Exibindo planilha importada' },
      ]}
      loading={isLoading}
      crudActionsProps={{
        onDelete,
        routeNewRegister:   dataImportsRoute.new.build(),
        permissionKeyCode:  'DataImport'
      }}
    >
      <Card>
        <CardContent>
          <GridContainer>
            <Col sm={2}>
              <InputShow
                label="ID"
                value={dataImport?.id}
              />
            </Col>
            <Col sm={5}>
              <InputShow
                label="Fazenda"
                value={dataImport?.farm?.name}
              />
            </Col>
            <Col sm={5}>
              <InputShow
                label="Usuário"
                value={dataImport?.user?.name}
              />
            </Col>
          </GridContainer>
          { !!file &&
            <InputShow
              label="Arquivo"
              value={file?.filename}
              InputProps={{
                startAdornment:
                  <InputAdornment position="start" component='a' target="_blank" href={file.url}>
                    <CloudDownloadIcon />
                  </InputAdornment>
              }}
            />
          }
        </CardContent>
      </Card>
    </LayoutShow>
  );
}

const mapStateToProps = state => ({
  isLoading: state.dataImports.isLoading,
  dataImport: state.dataImports.current,
});

export default connect(mapStateToProps)(Show);