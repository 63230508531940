import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const Footer = styled.footer`
  min-height: 160px;
  margin-top: 20px;
  margin-bottom: 20px;
  /*border-top: 1px #907c7c;*/
  /*border-top-style: dashed;*/
  padding-bottom: 20px;
  a {
    text-decoration: none
  }
`;

export const Container = styled.div`
  @media print {
    display: flex;
  }
  margin-top: 10px
`;

export const Content = styled.div`
  padding-left: 20px
`;

export const Icon = styled.div`
  margin-right: 6px;
  display: inline-block;
`;
export const Text = styled(Typography)`
  display: flex
`;

export const Box = styled.div`
  display: flex
`;