import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function RankingContent({rows}) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell><EmojiEventsIcon /></TableCell>
            <TableCell>Touro</TableCell>
            <TableCell>Fazenda</TableCell>
            <TableCell>Raça</TableCell>
            <TableCell align="right">IPG</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map( (row, idx) => (
            <TableRow key={row.code}>
              <TableCell component="th" scope="row">{idx+1}°</TableCell>
              <TableCell>{row.code} - {row.name}</TableCell>
              <TableCell>{row.farm?.name}</TableCell>
              <TableCell>{row.breed}</TableCell>
              <TableCell align="right">
                <strong>
                  {row.ipg_final}
                </strong>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
