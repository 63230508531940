import React, { useEffect, useState, useCallback } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import RefreshIcon from '@material-ui/icons/Refresh';
import FunctionsIcon from '@material-ui/icons/Functions';
import moment from 'moment-timezone';
import PrintIcon from "@material-ui/icons/Print";
import FilterListIcon from '@material-ui/icons/Tune';

import Button from '~/components/Button';
import Tooltip from '~/components/Tooltip';
import Spinner from '~/components/Spinner';
import BaseLayout from '~/components/BaseLayout';
import Request from '~/services/request';

import { Player } from '@lottiefiles/react-lottie-player';
import calcLoading from '~/assets/lotties/calc-animation.json'
import dnaLoading from '~/assets/lotties/dna-loading.json'

import Content from './Content';
import Filters from './Filters';

let timeoutCalcFetch = null;

export default function Layout({url, title}) {
  const [isFirst, setIsFirst] = useState(true)
  const [isCalculating, setIsCalculating] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [rows, setRows] = useState([])
  const [calcAt, setCalcAt] = useState(null)
  const [current, setCurrent] = useState( new Date() )
  const [allBulls, setAllBulls] = useState([])
  const [filtredBulls, setFiltredBulls] = useState([])
  const [filtredFarmIds, setFiltredFarmIds] = useState([])
  const [filtersIsOpen, setFiltresIsOpen] = useState(false)

  const fetchData = useCallback(
    async () => {
      setIsLoading(true)
      const response = await Request.get( url )
      setAllBulls(response.data.data)
      setCalcAt( response.data.calculated_at )
      setIsLoading(false)
      setIsFirst(false)
    },
    [url, setIsLoading],
  )

  useEffect(() => {
     setTimeout( fetchData, 5000);

    return () => {
      clearTimeout( timeoutCalcFetch )
    }
  } , [])

  useEffect(() => {
    let bulls = [...allBulls]

    if(filtredBulls.length){
      bulls = bulls.filter((bull) => filtredBulls.includes(bull.id))
    }

    if(filtredFarmIds.length){
      bulls = bulls.filter((bull) => filtredFarmIds.includes(bull.farm_id))
    }

    setRows(bulls)

  } , [filtredBulls, allBulls, filtredFarmIds])

  const recalc = useCallback(
    async () => {
      setIsCalculating( true )
      setCurrent( new Date() )
      await Request.post('/api/v1/recalc')
      timeoutCalcFetch = setTimeout( () => {
        checkRecalc()
      }, 10000)
    },
    [setIsCalculating, setCurrent],
  )

  const onApplyFilters = useCallback(
    ({bulls, farms}) => {
      setFiltredBulls(bulls)
      setFiltredFarmIds(farms)
      setFiltresIsOpen(false)
    },
    [setFiltredBulls]
  )

  const checkRecalc = useCallback(
    async () => {
      const response = await Request.get( url )

      if( moment(response.data.calculated_at).isAfter(current)){
        await fetchData()
        clearTimeout( timeoutCalcFetch )
        setIsCalculating(false)
        return
      }

      timeoutCalcFetch = setTimeout( () => {
        checkRecalc()
      }, 1000)

    },
    [setIsCalculating, setCurrent, current, url],
  )

  return (
    <BaseLayout showDefaultBreadcrumb={false}>
      <div className="only-print">
        <Box display="flex" justifyContent="flex-end">
          <Typography variant="caption">
            { moment().tz('America/Sao_Paulo').format("DD/MM/YYYY HH:mm") }
          </Typography>
        </Box>
      </div>

      <Box display="flex" p={1} justifyContent="space-between">
        <Typography variant="h5" style={{marginBottom: '10px'}}>
          {title}
        </Typography>
        <Box className="hidden-print" display="flex" p={1} justifyContent="flex-end">
          { !!calcAt &&
            <Typography variant="caption">
              Último recálculo
              <br/>
              { moment(calcAt).tz('America/Sao_Paulo').format("DD/MM/YYYY HH:mm") }
            </Typography>
          }
          <Button variant="text" onClick={() => setFiltresIsOpen(true) } style={{margin: '0px 5px'}}>
            <Tooltip title="Filtrar">
              <FilterListIcon />
            </Tooltip>
          </Button>
          <Button onClick={recalc} style={{margin: '0px 5px'}}>
            <Tooltip title="Recalcular">
              <FunctionsIcon />
            </Tooltip>
          </Button>
          <Button onClick={fetchData} color="green" style={{margin: '0px 5px'}}>
            <Tooltip title="Recarregar">
              <RefreshIcon />
            </Tooltip>
          </Button>
          <Button onClick={() => window.print() } color="grey">
            <Tooltip title="Imprimir">
              <PrintIcon />
            </Tooltip>
          </Button>
        </Box>
      </Box>

      <Filters
        isOpen={filtersIsOpen}
        allBulls={allBulls}
        filtredBulls={filtredBulls}
        onClose={()=> setFiltresIsOpen(false) }
        onApply={onApplyFilters}
      />

      { isCalculating && <>
        <Spinner loadingText="Calculando Ranking... Por favor, aguarde." />
        <div style={{width: '100%', height: '60vh'}}>
          <Player
            style={{width: '100%', height: '60vh'}}
            autoplay
            loop
            speed={1.5}
            src={calcLoading}
          />
        </div>
      </> }

      { !isCalculating && isFirst && isLoading &&
        <Box display="flex" p={1} justifyContent="center">
          <Paper elevation={3} style={{width: '80%'}}>
            <Typography variant="h5" align="center" style={{marginTop: '30px'}} color="primary">
              Carregando Ranking...
            </Typography>
            <div style={{width: '100%', height: '70vh'}}>
              <Player
                style={{width: '100%', height: '70vh'}}
                autoplay
                loop
                speed={1.5}
                src={dnaLoading}
              />
            </div>
          </Paper>
        </Box>
      }

      { !isCalculating && !isFirst && isLoading && <Spinner loadingText="Carregando.." /> }
      { !isCalculating && !isLoading && <Content rows={rows} />
      }
    </BaseLayout>
  );
}
