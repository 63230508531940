import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import CrudEdit from '~/containers/CrudEdit';

import { calcSettingsApi } from '~/routes/api';
import Request from '~/services/request';
import { useSelector } from 'react-redux';

import InputControl from '~/components/Input/InputControl';
import InputNumber from '~/components/Input/InputNumber';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';

import IndividualWeights from './components/IndividualWeights';
import CompoundWeights from './components/CompoundWeights';

const attrNames = {
  self_percentage: 'Peso dados do Animal %',
  father_percentage: 'Peso dados do pai %',
  mother_percentage: 'Peso dados da mãe %',
  father_grandfather_percentage: 'Peso dados avô paterno %',
  paternal_great_grandfather_percentage: 'Peso dados bisavô paterno %',
  udder_percentage: '% Úbere',
  solid_percentage: '% Sólidos',
  fertility_percentage: '% Index Fert.',
  milk_percentage: '% Leite',
  composite_paws_legs_percentage: '% Composto de patas e pernas',
  body_compound_percentage: '% Composto corporal',

  udder_posterior_ligament_percentage:       '% Ligamento Post. Úbere',
  udder_anterior_ligament_percentage:        '% Ligamento Ant. Úbere',
  udder_depth_percentage:                    '% Profundidade Úbere',
  udder_width_percentage:                    '% Largura Úbere',
  udder_height_percentage:                   '% Altura Úbere',
  udder_posterior_tits_percentage:            '% Colocação Tetos Posteriores',
  udder_anterior_tits_percentage:            '% Colocação Tetos Anteriores',
  solid_compound_fat_vol_percentage:         '% Volume de gordura',
  solid_compound_protein_vol_percentage:     '% Volume de proteína',
  solid_compound_fat_percent_percentage:     '% do Percentual de gordura',
  solid_compound_protein_percent_percentage: '% do Percentual de proteína',
  solid_compound_somatic_cells_percentage:   '% Células Somáticas',
  fertility_idx_ccr_percentage:              '% CCR (Taxa de Prenhes Vacas)',
  fertility_idx_hcr_percentage:              '% HCR (Taxa de Prenhes Novilhas)',
  fertility_idx_dpr_percentage:              '% DPR (Taxa de Prenhes Filhas)',

  composite_paws_legs_side_view_percentage:   '% Pernas Vista Lateral',
  composite_paws_legs_behind_view_percentage: '% Pernas Vistas por Trás',
  composite_paws_legs_hull_angle_percentage:  '% Ângulo de Cascos',
  body_compound_stature_percentage:           '% Estatura',
  body_compound_strength_percentage:          '% Força',
  body_compound_depth_percentage:             '% Prof. Corporal',
  body_compound_milk_percentage:              '% Caracter Leiteiro',
  body_compound_croup_angle_percentage:       '% Ângulo de Garupa',
  body_compound_croup_width_percentage:       '% Largura de Garupa',
}

function attrName(attr){ return attrNames[attr] }

export default function CalcSettings() {
  const [record, setRecord] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { access_type } = useSelector(state => state.userProfile.data);

  useEffect(() => {
    Request.get(calcSettingsApi.show.build()).then( resp => {
      setRecord( resp.data.data )
      setIsLoading(false)
    })
  }, [])

  const onSubmit = useCallback( () => {
    setIsLoading(true)
    Request.put(calcSettingsApi.save.build(), {
      data: {
        attributes: record
      }
    }).then( resp => {
      setRecord( resp.data.data )
      setIsLoading(false)
      toast.success('Dados salvos com sucesso!');
    }).catch( e => {
      console.error(e);
      toast.error('Falha ao salvar!');
    } )
  }, [record]);

  const onChange = useCallback(attrs => {
    setRecord( {...record, ...attrs} );
  }, [record, setRecord]);

  return (
    <CrudEdit
      isLoading={isLoading}
      onSubmit={onSubmit}
      breadcrumbs={[{text: 'Configuração de cálculos'}]}
      footerActionsProps={{isLoading, showSaveAndContinue: false}}
    >
      <Typography variant="h5">Peso por geração</Typography>
      <Card>
        <CardContent>
          <GridContainer>
            <Col sm={4}>
              <InputControl
                required
                name="self_percentage"
                label={attrName('self_percentage')}
                value={record?.self_percentage || 0}
                inputComponent={InputNumber}
                onChange={onChange}
                InputProps={{ precision: 0 }}
              />
            </Col>
            <Col sm={4}>
              <InputControl
                required
                name="father_percentage"
                label={attrName('father_percentage')}
                value={record?.father_percentage || 0}
                inputComponent={InputNumber}
                onChange={onChange}
                InputProps={{ precision: 0 }}
              />
            </Col>
            <Col sm={4}>
              <InputControl
                required
                name="mother_percentage"
                label={attrName('mother_percentage')}
                value={record?.mother_percentage || 0}
                inputComponent={InputNumber}
                onChange={onChange}
                InputProps={{ precision: 0 }}
              />
            </Col>
            <Col sm={4}>
              <InputControl
                required
                name="father_grandfather_percentage"
                label={attrName('father_grandfather_percentage')}
                value={record?.father_grandfather_percentage || 0}
                inputComponent={InputNumber}
                onChange={onChange}
                InputProps={{ precision: 0 }}
              />
            </Col>
            <Col sm={4}>
              <InputControl
                required
                name="paternal_great_grandfather_percentage"
                label={attrName('paternal_great_grandfather_percentage')}
                value={record?.paternal_great_grandfather_percentage || 0}
                inputComponent={InputNumber}
                onChange={onChange}
                InputProps={{ precision: 0 }}
              />
            </Col>
          </GridContainer>
        </CardContent>
      </Card>
      <br/>

      <CompoundWeights attrName={attrName} record={record} onChange={onChange} />
      <br/>
      { access_type === 'master' &&
        <IndividualWeights attrName={attrName} record={record} onChange={onChange} />
      }
    </CrudEdit>
  );
}