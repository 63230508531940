import SchemaBuilder from './SchemaBuilder';
import userAcessTypeEnum from '~/enums/userAcessTypeEnum';

const hiddenList = {tableView: false, hidden: true, isFilter:  false}

const columns = {
  id: {
    type: 'integer',
    width: 80,
    title: 'ID'
  },
  name: {
    type: 'string',
    width: 180,
    default: '',
    title: 'Nome'
  },
  email: {
    type: 'string',
    width: 180,
    default: '',
    title: 'E-mail'
  },
  access_type: {
    type: 'enum',
    width: 200,
    default: '',
    title: 'Tipo acesso',
    _enum_: userAcessTypeEnum
  },
  password: {
    type: 'string',
    width: 250,
    default: '',
    title: 'Senha de Acesso',
    isFilter: false,
    tableView: false,
  },
  password_confirmation: {
    type: 'string',
    width: 200,
    default: '',
    title: 'Confirmação da senha',
    isFilter: false,
    tableView: false,
  },
  address: {
    type: 'object',
    title: 'Endereço',
    isFilter: false,
    tableView: false,
  },
  created_at: {
    type: 'datetime',
    title: 'Criado em'
  },
  avatar:        { type: 'file',  ...hiddenList, title: 'Foto'},
};

const userSchema = new SchemaBuilder( columns );

export default userSchema;