import styled from 'styled-components';
import MUPaper from '@material-ui/core/Paper';

const getWidth = width => `calc(100vw - ${width}px)`;

export const Container = styled.div`
  display: block;
  width: ${ ({isMobile, drawerOpen}) => isMobile ? getWidth(32) : (drawerOpen ? getWidth(345) : getWidth(160)) };
  transition: width 400ms ease-in-out;
  @media print {
    width: 100%
  }
`;

export const Paper = styled(MUPaper)`
  position: relative;
`;

export const ToolbarRootComponent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-right: 10px;
`;
