import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import InputControl from '~/components/Input/InputControl';
import InputNumber from '~/components/Input/InputNumber';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';

const dividerStyle = {marginTop: '20px', marginBottom: '10px'};

export default function IndividualWeights({attrName, record, onChange}) {
  return (
    <>
      <Typography variant="h5">Pesos individuais</Typography>
      <Card>
        <CardContent>
          <fieldset>
            <legend><Typography variant="subtitle1">Composto de Úbere</Typography></legend>
            <GridContainer>
              <Col sm={4} md={3}>
                <InputControl
                  required
                  name="udder_posterior_ligament_percentage"
                  label={attrName('udder_posterior_ligament_percentage')}
                  value={record?.udder_posterior_ligament_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4} md={3}>
                <InputControl
                  required
                  name="udder_anterior_ligament_percentage"
                  label={attrName('udder_anterior_ligament_percentage')}
                  value={record?.udder_anterior_ligament_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4} md={3}>
                <InputControl
                  required
                  name="udder_anterior_tits_percentage"
                  label={attrName('udder_anterior_tits_percentage')}
                  value={record?.udder_anterior_tits_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4} md={3}>
                <InputControl
                  required
                  name="udder_posterior_tits_percentage"
                  label={attrName('udder_posterior_tits_percentage')}
                  value={record?.udder_posterior_tits_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4}>
                <InputControl
                  required
                  name="udder_depth_percentage"
                  label={attrName('udder_depth_percentage')}
                  value={record?.udder_depth_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4}>
                <InputControl
                  required
                  name="udder_width_percentage"
                  label={attrName('udder_width_percentage')}
                  value={record?.udder_width_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4}>
                <InputControl
                  required
                  name="udder_height_percentage"
                  label={attrName('udder_height_percentage')}
                  value={record?.udder_height_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
            </GridContainer>
          </fieldset>
          <Divider style={dividerStyle}/>

          <fieldset>
            <legend><Typography variant="subtitle1">Composto de Sólidos</Typography></legend>
            <GridContainer>
              <Col sm={4}>
                <InputControl
                  required
                  name="solid_compound_fat_vol_percentage"
                  label={attrName('solid_compound_fat_vol_percentage')}
                  value={record?.solid_compound_fat_vol_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4}>
                <InputControl
                  required
                  name="solid_compound_protein_vol_percentage"
                  label={attrName('solid_compound_protein_vol_percentage')}
                  value={record?.solid_compound_protein_vol_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4}>
                <InputControl
                  required
                  name="solid_compound_somatic_cells_percentage"
                  label={attrName('solid_compound_somatic_cells_percentage')}
                  value={record?.solid_compound_somatic_cells_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={6}>
                <InputControl
                  required
                  name="solid_compound_fat_percent_percentage"
                  label={attrName('solid_compound_fat_percent_percentage')}
                  value={record?.solid_compound_fat_percent_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={6}>
                <InputControl
                  required
                  name="solid_compound_protein_percent_percentage"
                  label={attrName('solid_compound_protein_percent_percentage')}
                  value={record?.solid_compound_protein_percent_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
            </GridContainer>
          </fieldset>

          <Divider style={dividerStyle}/>

          <fieldset>
            <legend><Typography variant="subtitle1">Composto de Saúde/Fertilidade</Typography></legend>
            <GridContainer>
              <Col sm={4}>
                <InputControl
                  required
                  name="fertility_idx_ccr_percentage"
                  label={attrName('fertility_idx_ccr_percentage')}
                  value={record?.fertility_idx_ccr_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4}>
                <InputControl
                  required
                  name="fertility_idx_hcr_percentage"
                  label={attrName('fertility_idx_hcr_percentage')}
                  value={record?.fertility_idx_hcr_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4}>
                <InputControl
                  required
                  name="fertility_idx_dpr_percentage"
                  label={attrName('fertility_idx_dpr_percentage')}
                  value={record?.fertility_idx_dpr_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
            </GridContainer>
          </fieldset>
          <Divider style={dividerStyle}/>


          <fieldset>
            <legend><Typography variant="subtitle1">Composto de Patas e Pernas</Typography></legend>
            <GridContainer>
              <Col sm={4}>
                <InputControl
                  required
                  name="composite_paws_legs_side_view_percentage"
                  label={attrName('composite_paws_legs_side_view_percentage')}
                  value={record?.composite_paws_legs_side_view_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4}>
                <InputControl
                  required
                  name="composite_paws_legs_behind_view_percentage"
                  label={attrName('composite_paws_legs_behind_view_percentage')}
                  value={record?.composite_paws_legs_behind_view_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4}>
                <InputControl
                  required
                  name="composite_paws_legs_hull_angle_percentage"
                  label={attrName('composite_paws_legs_hull_angle_percentage')}
                  value={record?.composite_paws_legs_hull_angle_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
            </GridContainer>
          </fieldset>

          <Divider style={dividerStyle}/>


          <fieldset>
            <legend><Typography variant="subtitle1">Composto Corporal</Typography></legend>
            <GridContainer>
              <Col sm={4}>
                <InputControl
                  required
                  name="body_compound_stature_percentage"
                  label={attrName('body_compound_stature_percentage')}
                  value={record?.body_compound_stature_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4}>
                <InputControl
                  required
                  name="body_compound_strength_percentage"
                  label={attrName('body_compound_strength_percentage')}
                  value={record?.body_compound_strength_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4}>
                <InputControl
                  required
                  name="body_compound_depth_percentage"
                  label={attrName('body_compound_depth_percentage')}
                  value={record?.body_compound_depth_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4}>
                <InputControl
                  required
                  name="body_compound_milk_percentage"
                  label={attrName('body_compound_milk_percentage')}
                  value={record?.body_compound_milk_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4}>
                <InputControl
                  required
                  name="body_compound_croup_angle_percentage"
                  label={attrName('body_compound_croup_angle_percentage')}
                  value={record?.body_compound_croup_angle_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4}>
                <InputControl
                  required
                  name="body_compound_croup_width_percentage"
                  label={attrName('body_compound_croup_width_percentage')}
                  value={record?.body_compound_croup_width_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
            </GridContainer>
          </fieldset>
        </CardContent>
      </Card>
    </>
  );
}