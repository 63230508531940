import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import InputControl from '~/components/Input/InputControl';
import InputNumber from '~/components/Input/InputNumber';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';

const attrNames = {
  self: 'Animal',
  father: 'Pai',
  mother: 'Mãe',
  father_grandfather: 'Avô paterno',
  paternal_great_grandfather: 'Bisavô paterno'
}

function attrTitle(attr){ return attrNames[attr] }

export default function CompoundWeights({attrName, record, onChange}) {
  return (
    <>
      <Typography variant="h5">Pesos dos compostos</Typography>
      <Card>
        <CardContent>

          <fieldset>
            <legend><Typography variant="subtitle1">{attrName('udder_percentage')}</Typography></legend>
            <GridContainer>
              <Col sm={4} md={2}>
                <InputControl
                  required
                  name="self_udder_percentage"
                  label={attrTitle('self')}
                  value={record?.self_udder_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4} md={2}>
                <InputControl
                  required
                  name="father_udder_percentage"
                  label={attrTitle('father')}
                  value={record?.father_udder_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4} md={2}>
                <InputControl
                  required
                  name="mother_udder_percentage"
                  label={attrTitle('mother')}
                  value={record?.mother_udder_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={6} md={3}>
                <InputControl
                  required
                  name="father_grandfather_udder_percentage"
                  label={attrTitle('father_grandfather')}
                  value={record?.father_grandfather_udder_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={6} md={3}>
                <InputControl
                  required
                  name="paternal_great_grandfather_udder_percentage"
                  label={attrTitle('paternal_great_grandfather')}
                  value={record?.paternal_great_grandfather_udder_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
            </GridContainer>
          </fieldset>

          <fieldset>
            <legend><Typography variant="subtitle1">{attrName('solid_percentage')}</Typography></legend>
            <GridContainer>
              <Col sm={4} md={2}>
                <InputControl
                  required
                  name="self_solid_percentage"
                  label={attrTitle('self')}
                  value={record?.self_solid_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4} md={2}>
                <InputControl
                  required
                  name="father_solid_percentage"
                  label={attrTitle('father')}
                  value={record?.father_solid_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4} md={2}>
                <InputControl
                  required
                  name="mother_solid_percentage"
                  label={attrTitle('mother')}
                  value={record?.mother_solid_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={6} md={3}>
                <InputControl
                  required
                  name="father_grandfather_solid_percentage"
                  label={attrTitle('father_grandfather')}
                  value={record?.father_grandfather_solid_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={6} md={3}>
                <InputControl
                  required
                  name="paternal_great_grandfather_solid_percentage"
                  label={attrTitle('paternal_great_grandfather')}
                  value={record?.paternal_great_grandfather_solid_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
            </GridContainer>
          </fieldset>

          <fieldset>
            <legend><Typography variant="subtitle1">{attrName('fertility_percentage')}</Typography></legend>
            <GridContainer>
              <Col sm={4} md={2}>
                <InputControl
                  required
                  name="self_fertility_percentage"
                  label={attrTitle('self')}
                  value={record?.self_fertility_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4} md={2}>
                <InputControl
                  required
                  name="father_fertility_percentage"
                  label={attrTitle('father')}
                  value={record?.father_fertility_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4} md={2}>
                <InputControl
                  required
                  name="mother_fertility_percentage"
                  label={attrTitle('mother')}
                  value={record?.mother_fertility_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={6} md={3}>
                <InputControl
                  required
                  name="father_grandfather_fertility_percentage"
                  label={attrTitle('father_grandfather')}
                  value={record?.father_grandfather_fertility_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={6} md={3}>
                <InputControl
                  required
                  name="paternal_great_grandfather_fertility_percentage"
                  label={attrTitle('paternal_great_grandfather')}
                  value={record?.paternal_great_grandfather_fertility_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
            </GridContainer>
          </fieldset>
          <fieldset>
            <legend><Typography variant="subtitle1">{attrName('milk_percentage')}</Typography></legend>
            <GridContainer>
              <Col sm={4} md={2}>
                <InputControl
                  required
                  name="self_milk_percentage"
                  label={attrTitle('self')}
                  value={record?.self_milk_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4} md={2}>
                <InputControl
                  required
                  name="father_milk_percentage"
                  label={attrTitle('father')}
                  value={record?.father_milk_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4} md={2}>
                <InputControl
                  required
                  name="mother_milk_percentage"
                  label={attrTitle('mother')}
                  value={record?.mother_milk_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={6} md={3}>
                <InputControl
                  required
                  name="father_grandfather_milk_percentage"
                  label={attrTitle('father_grandfather')}
                  value={record?.father_grandfather_milk_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={6} md={3}>
                <InputControl
                  required
                  name="paternal_great_grandfather_milk_percentage"
                  label={attrTitle('paternal_great_grandfather')}
                  value={record?.paternal_great_grandfather_milk_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
            </GridContainer>
          </fieldset>
          <fieldset>
            <legend><Typography variant="subtitle1">{attrName('composite_paws_legs_percentage')}</Typography></legend>
            <GridContainer>
              <Col sm={4} md={2}>
                <InputControl
                  required
                  name="self_composite_paws_legs_percentage"
                  label={attrTitle('self')}
                  value={record?.self_composite_paws_legs_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4} md={2}>
                <InputControl
                  required
                  name="father_composite_paws_legs_percentage"
                  label={attrTitle('father')}
                  value={record?.father_composite_paws_legs_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4} md={2}>
                <InputControl
                  required
                  name="mother_composite_paws_legs_percentage"
                  label={attrTitle('mother')}
                  value={record?.mother_composite_paws_legs_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={6} md={3}>
                <InputControl
                  required
                  name="father_grandfather_composite_paws_legs_percentage"
                  label={attrTitle('father_grandfather')}
                  value={record?.father_grandfather_composite_paws_legs_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={6} md={3}>
                <InputControl
                  required
                  name="paternal_great_grandfather_composite_paws_legs_percentage"
                  label={attrTitle('paternal_great_grandfather')}
                  value={record?.paternal_great_grandfather_composite_paws_legs_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
            </GridContainer>
          </fieldset>

          <fieldset>
            <legend><Typography variant="subtitle1">{attrName('body_compound_percentage')}</Typography></legend>
            <GridContainer>
              <Col sm={4} md={2}>
                <InputControl
                  required
                  name="self_body_compound_percentage"
                  label={attrTitle('self')}
                  value={record?.self_body_compound_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4} md={2}>
                <InputControl
                  required
                  name="father_body_compound_percentage"
                  label={attrTitle('father')}
                  value={record?.father_body_compound_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={4} md={2}>
                <InputControl
                  required
                  name="mother_body_compound_percentage"
                  label={attrTitle('mother')}
                  value={record?.mother_body_compound_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={6} md={3}>
                <InputControl
                  required
                  name="father_grandfather_body_compound_percentage"
                  label={attrTitle('father_grandfather')}
                  value={record?.father_grandfather_body_compound_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
              <Col sm={6} md={3}>
                <InputControl
                  required
                  name="paternal_great_grandfather_body_compound_percentage"
                  label={attrTitle('paternal_great_grandfather')}
                  value={record?.paternal_great_grandfather_body_compound_percentage || 0}
                  inputComponent={InputNumber}
                  onChange={onChange}
                  InputProps={{ precision: 0 }}
                />
              </Col>
            </GridContainer>
          </fieldset>
        </CardContent>
      </Card>
    </>
  );
}