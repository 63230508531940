class EnumBase {
  constructor(enumValue, extra={}){
    this.array = enumValue;
    this.extra = extra;
  }

  objects(key='id', label='name'){
    const obj = {};

    this.array.forEach( (_enum) => {
      obj[_enum[key]] = _enum[label];
    });

    return obj;
  }

  getName(key){
    return this.array.find( e => e.id === key )?.name || ''
  }
}

export default EnumBase;
