import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( () => ({
  list: {
    width: 500,
    height: 'inherit',
    justifyContent: 'space-evenly',
    display: 'flex',
    flexDirection: 'column',
    padding: 50 ,
  },
  fullList: {
    width: 'auto',
  },
  large: {
    width: 100,
    height: 100,
  },
  avatar: {
    display: 'flex',
    flexDirection: 'row',
    width: '70%',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  bottomButtons:{
    alignSelf: 'flex-end',
    paddingRight: 50,
  },
  cancelButton: {
    marginLeft: 25,
  },
  formDiv: {
    width: '90%', 
    flexDirection: 'column',
  },
  textDivs:{
    alignSelf: 'center',
  }
}));

export default useStyles;