import React from 'react';
import { Switch } from 'react-router-dom';

import { dataImportsRoute } from '~/routes'
import RouteWrapper from '~/routes/RouteWrapper';
import List from './List';
import Show from './Show';
import New  from './New';

export default function DataImportRoutesComponent() {
  return (
    <Switch>
      <RouteWrapper
        exact
        isPrivate
        path={dataImportsRoute.new.path}
        component={New}
        permissionKeyCode="DataImport"
        permission="add"
      />
      <RouteWrapper
        exact
        isPrivate
        path={dataImportsRoute.clone.path}
        component={New}
        permissionKeyCode="DataImport"
        permission="add"
      />
      <RouteWrapper
        exact
        isPrivate
        path={dataImportsRoute.show.path}
        component={Show}
        permissionKeyCode="DataImport"
        permission="see"
      />

      <RouteWrapper
        isPrivate
        redirectIfNotExact
        path={dataImportsRoute.list.path}
        component={List}
        permissionKeyCode="DataImport"
        permission="see"
      />
    </Switch>
  );
}
