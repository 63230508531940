import RecordBase from './Base';
import dataImportSchema from './schema/dataImportSchema';
import ArchiveRecord from '~/records/ArchiveRecord';
import FarmRecord from './FarmRecord'

export default class DataImportRecord extends RecordBase {
  static defaultAttributes = dataImportSchema.defaultAttributes;

  static _attributeNames = null;

  static get attributeNames() {
    if(!this._attributeNames){
      this._attributeNames = dataImportSchema.attributeNames;
    }
    return this._attributeNames;
  }

  static belongsTo = {
    spreadsheet: ArchiveRecord,
    farm: FarmRecord
  };

  toParams(extra={}) {
    return {
      data:{
        attributes:{
          data_type: 'any',
          spreadsheet_id: this.get('spreadsheet')?.get('id') || null,
          farm_id: this.get('farm')?.get('id'),
          ...extra
        }
      }
    };
  }
}