import Request from '~/services/request';
import { farmsApi } from '~/routes/api';
import queryStringParser from '~/utils/queryStringParser';

export const findRecordRequest = (id, args={}) => {
  return Request.get( `${ farmsApi.show.build({id}) }?${queryStringParser(args)}` );
};

export const fetchListRecordsRequest = (args={}, requestSource) => {
  return Request.get( `${ farmsApi.list.build() }?${queryStringParser(args)}`, {
    cancelToken: requestSource?.token
  } );
};

export const destroyRecordRequest = (id, args={}) => {
  return Request.del( farmsApi.destroy.build({id}), args );
};

export const updateRecordRequest = (id, data={}, args={} ) => {
  return Request.put( `${ farmsApi.update.build({id}) }?${queryStringParser(args)}`, data );
};

export const createRecordRequest = (data={}, args={}) => {
  return Request.post( `${ farmsApi.create.build() }?${queryStringParser(args)}`, data );
};