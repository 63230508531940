import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  memo,
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef
} from 'react';
import PropTypes from 'prop-types';

import {
  SortingState,
  PagingState,
  CustomPaging,
  DataTypeProvider,
  IntegratedSelection,
  SelectionState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table as DXTable,
  TableHeaderRow,
  TableSelection,
  TableFixedColumns,
  TableColumnResizing,
  Toolbar,
  ColumnChooser,
  TableColumnVisibility,
  DragDropProvider,
  PagingPanel,
  TableColumnReordering,
} from '@devexpress/dx-react-grid-material-ui';
import Tooltip from '@material-ui/core/Tooltip';
import ColumnVisibilityIcon from '@material-ui/icons/ViewColumnRounded';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import DrawerMenuContext from '~/contexts/DrawerMenuContext';
import QueryParamsContext from '~/contexts/QueryParamsContext';
import FilterContext from '~/contexts/FilterContext';
import Request from '~/services/request';
import isPresent from '~/utils/isPresent';
import SchemaBuilder from '~/records/schema/SchemaBuilder';

import IconButton from '~/components/IconButton';
import ConfirmationDialog from '~/components/ConfirmationDialog';
import Filter from '~/components/Filter';

import Loading from './components/Loading';
import NoDataRow from './components/NoDataRow';
import Actions from './components/Actions';
import DateTimeTypeProvider from './components/DateTimeTypeProvider';
import DateTypeProvider from './components/DateTypeProvider';
import EnumTypeProvider from './components/EnumTypeProvider';
import NumberTypeProvider from './components/NumberTypeProvider';
import BooleanTypeProvider from './components/BooleanTypeProvider';

import { Container, Paper, ToolbarRootComponent } from './styles';

const ACTION_COLUMN_NAME = 'actions';
let firstLoading = true;

function Table({
  tableCellColumns,
  tableRowColumnsComponent,
  tableRowColumns,
  columnsVisibility,
  columnsOverride,
  fixedColumns,
  showPaggination,
  showSorting,
  defaultPaging,
  actions,
  enableColumnsResizing,
  enableTableSelection,
  extraActions,
  filterProps,
  permissionKeyCode,
  routes,
  onDelete, // async function
  onDeleteFailed,
  isLoading,
  rows,
  total,
  handleFetch, // async function
  handleFetchFailed,
  schema,
  selectedRows,
  onSelectionChange,
  onEdit,
}, tableRef) {
  const [pageSizes] = useState([5, 10, 20, 50, 100, 200]);
  const [tableColumnExtensions] = useState(tableRowColumns || schema?.tableRowColumns || []);
  const [columns] = useState( tableCellColumns || schema?.tableCellColumns || [] );
  const [columnOrder, setColumnOrder] = useState(
    useMemo(() => columns.map(column => column.name), [columns])
  );
  const [dataProviderKeys] = useState(useMemo(
    () => columnsOverride.map(column => column.name).concat(ACTION_COLUMN_NAME),
    [columnsOverride]
  ));
  const { drawerMenuStorageState } = useContext(DrawerMenuContext);
  const { params, setParams, paramsAlreadyIncluded } = useContext(QueryParamsContext);
  const { filterOpen, setFilterOpen } = useContext(FilterContext);
  const requestSource = useMemo(() => Request.CancelToken.source(), []);
  const confirmationDialogRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => () => {
    firstLoading = true;
    requestSource.cancel('CRUD_FETCH_CANCELED');
  }, []);

  useEffect(
    () => {
      if (!params.finishedInitialLoading) return;

      if (firstLoading && isPresent(defaultPaging)) {
        firstLoading = false;

        if (!paramsAlreadyIncluded(defaultPaging)) {
          setParams(defaultPaging);
          return;
        }
      }

      searchRecords();
    },
    [
      params.page,
      params.per_page,
      params.sort,
      params.direction,
      params.filters,
      params.qs,
      params.finishedInitialLoading
    ]
  );

  const searchRecords = useCallback(
    async () => {
      try {
        await handleFetch( location.search, requestSource )
      } catch(e) {
        console.error(e);
        handleFetchFailed(e)
      }
    },
    [handleFetch, handleFetchFailed, requestSource]
  )

  const onSortingChange = useCallback(
    ([{ columnName, direction }]) => {
      setParams({
        sort: columnName,
        direction
      });
    },
    [setParams]
  )

  const changePageSize = useCallback(
    (size) => {
      setParams({ per_page: size });
    },
    [setParams]
  )

  const onCurrentPageChange = useCallback(
    (newPage) => {
      setParams({ page: ++newPage }, true);
    },
    [setParams]
  )

  const handleDelete = useCallback(
    (row) => {
      const { current: dialog } = confirmationDialogRef;
      dialog.open();
      dialog.onConfirm( async () => {
        try {
          await onDelete(row);
        } catch(err) {
          onDeleteFailed(err, row)
        }
      });
    },
    [confirmationDialogRef, onDelete, onDeleteFailed]
  )

  const onFilter = useCallback(
    (compress, _json, size) => {
      setFilterOpen(false);
      setParams({ filters: compress, size });
    },
    [setFilterOpen, setParams],
  )

  function onFormatterComponent({ row, column }) {
    if (column.name === ACTION_COLUMN_NAME) {
      return (
        <Actions
          actions={actions}
          permissionKeyCode={permissionKeyCode}
          extraActions={extraActions ? () => extraActions(row) : null}
          showPath={ routes?.showPath ? routes.showPath(row.id) : undefined }
          editPath={ routes?.editPath ? routes.editPath(row.id) : undefined }
          onEdit={ onEdit ? (ev) => onEdit(row, ev) : undefined }
          onDelete={() => handleDelete(row) }
        />
      );
    }

    const colOverride = columnsOverride.find(
      colOver => colOver.name === column.name
    );

    try {
      return colOverride.formatterComponent(column.name, row);
    } catch(e) {
      console.error(e);
      return '>>ERROR<<'
    }
  }

  const visibilityDefaultHidden = useMemo(
    () => columnsVisibility?.defaultHidden || schema?.defaultHiddenRowColumns || [],
    [columnsVisibility, schema]
  );

  const visibilityColumnsExtensions = useMemo(
    () => [
      ...columnsVisibility.columnsExtensions,
      { columnName: ACTION_COLUMN_NAME, togglingEnabled: false }
    ],
    [columnsVisibility, schema]
  );

  const dateColumns = useMemo(
    () => columns.filter(e => e.type === 'date').map(e => e.name),
    [columns]
  );

  const datetimeColumns = useMemo(
    () => columns.filter(e => e.type === 'datetime').map(e => e.name),
    [columns]
  );

  const enumColumns = useMemo(
    () => columns.filter(e => e.type === 'enum').map(e => e.name),
    [columns]
  );

  const numericColumns = useMemo(
    () => columns.filter(
      e => ['integer','float', 'numeric', 'currency'].includes(e.type)
    ).map(e => e.name),
    [columns]
  );

  const booleanColumns = useMemo(
    () => columns.filter(
      e => e.type === 'boolean'
    ).map(e => e.name),
    [columns]
  );

  useImperativeHandle(
    tableRef,
    () => ({
      get dialogRef() {
        return confirmationDialogRef;
      },
      get columnOrder(){ return columnOrder },
      setColumnOrder(value){ setColumnOrder(value); }
    }),
    [
      searchRecords,
      confirmationDialogRef,
      columnOrder,
      setColumnOrder
    ]
  );

  return (
    <Container drawerOpen={drawerMenuStorageState} isMobile={isMobile}>
      <Paper>
        <Grid
          rows={rows}
          columns={columns}
        >
          { datetimeColumns.length > 0 &&  <DateTimeTypeProvider for={ datetimeColumns } /> }
          { dateColumns.length > 0 &&  <DateTypeProvider for={ dateColumns } /> }
          { enumColumns.length > 0 &&  <EnumTypeProvider for={ enumColumns } /> }
          { numericColumns.length > 0 &&  <NumberTypeProvider for={ numericColumns } /> }
          { booleanColumns.length > 0 &&  <BooleanTypeProvider for={ booleanColumns } /> }

          <DataTypeProvider
            for={dataProviderKeys}
            formatterComponent={onFormatterComponent}
          />

          {/* Para permitir que as colunas sejam movidas de posição */}
          <DragDropProvider />

          {showSorting && (
            <SortingState onSortingChange={onSortingChange} />
          )}

          {showPaggination && [
            <PagingState
              key="@dx-paging-ps"
              currentPage={params.page - 1}
              onCurrentPageChange={onCurrentPageChange}
              pageSize={params.per_page}
              onPageSizeChange={changePageSize}
            />,
            <CustomPaging key="@dx-paging-cp" totalCount={total} />
          ]}

          <SelectionState
            selection={selectedRows}
            onSelectionChange={onSelectionChange}
          />
          <IntegratedSelection />

          <DXTable
            columnExtensions={tableColumnExtensions}
            noDataRowComponent={({ children }) => (
              <NoDataRow loading={isLoading} colSpan={children.length} />
            )}
            cellComponent={
              tableRowColumnsComponent
                ? tableRowColumnsComponent
                : (props) => <DXTable.Cell {...props} />
            }
          />
          <TableColumnReordering
            order={columnOrder}
            onOrderChange={setColumnOrder}
          />

          {enableColumnsResizing && tableColumnExtensions.length && (
            <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />
          )}

          <TableHeaderRow showSortingControls={showSorting} />

          {enableTableSelection && <TableSelection />}

          <TableFixedColumns
            leftColumns={fixedColumns.leftColumns}
            rightColumns={fixedColumns.rightColumns}
          />

          <Toolbar
            rootComponent={({ children }) => (
              <ToolbarRootComponent>
                {children}
                {/* Implement more components here */}
              </ToolbarRootComponent>
            )}
          />

          {columnsVisibility.visible && [
            <TableColumnVisibility
              key="@dx-table-tcv"
              defaultHiddenColumnNames={visibilityDefaultHidden}
              columnExtensions={visibilityColumnsExtensions}
            />,
            <ColumnChooser
              key="@dx-table-ch"
              toggleButtonComponent={({ buttonRef, onToggle}) => (
                <IconButton buttonRef={buttonRef} onClick={onToggle}>
                  <Tooltip title="Selecionar Colunas">
                    <ColumnVisibilityIcon />
                  </Tooltip>
                </IconButton>
              )}
            />
          ]}

          {showPaggination && (
            <PagingPanel
              pageSizes={pageSizes}
              messages={{ rowsPerPage: 'Registros por página' }}
            />
          )}
        </Grid>
        {isLoading && <Loading />}
      </Paper>
      <ConfirmationDialog
        ref={confirmationDialogRef}
        title="Atenção"
        description="Deseja excluir este registro?"
      />
      { isPresent( filterProps?.columns ) &&
        <Filter
          {...filterProps}
          open={filterOpen}
          filter={params.filters}
          onCancel={() => setFilterOpen(false)}
          onFilter={onFilter}
        />
      }
    </Container>
  );
}

const TableComponent = forwardRef(Table);

TableComponent.defaultProps = {
  actions: [],
  permissionKeyCode: '',
  enableColumnsResizing: true,
  enableTableSelection: false,
  extraActions: null,
  columnsVisibility: {
    visible: true,
    columnsExtensions: []
  },
  columnsOverride: [],
  fixedColumns: {
    rightColumns: [ACTION_COLUMN_NAME]
  },
  showPaggination: true,
  showSorting: true,
  defaultPaging: {},
  filterProps: {
    columns: []
  },
  selectedRows: [],
  handleFetchFailed: () => {}
}

TableComponent.propTypes = {
  selectedRows: PropTypes.array,
  onSelectionChange: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onDeleteFailed: PropTypes.func,
  isLoading: PropTypes.bool,
  rows: PropTypes.array,
  total: PropTypes.number,
  handleFetch: PropTypes.func,
  handleFetchFailed: PropTypes.func,
  schema: PropTypes.instanceOf(SchemaBuilder),
  routes: PropTypes.shape({
    showPath: PropTypes.func,
    editPath: PropTypes.func,
  }),
  permissionKeyCode: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.oneOf(['show', 'edit', 'delete'])),
  extraActions: PropTypes.func,
  enableColumnsResizing: PropTypes.bool,
  enableTableSelection: PropTypes.bool,
  tableCellColumns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    getCellValue: PropTypes.func,
  })),
  tableRowColumns: PropTypes.arrayOf(PropTypes.shape({
    columnName: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    align: PropTypes.oneOf(['left', 'right', 'center']),
    wordWrapEnabled: PropTypes.bool,
  })),
  tableRowColumnsComponent: PropTypes.func,
  columnsVisibility: PropTypes.shape({
    visible: PropTypes.bool,
    defaultHidden: PropTypes.array,
    columnsExtensions: PropTypes.arrayOf(PropTypes.shape({
      columnName: PropTypes.string,
      togglingEnabled: PropTypes.bool
    }))
  }),
  columnsOverride: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    formatterComponent: PropTypes.func
  })),
  fixedColumns: PropTypes.shape({
    leftColumns: PropTypes.array,
    rightColumns: PropTypes.array
  }),
  showPaggination: PropTypes.bool,
  showSorting: PropTypes.bool,
  defaultPaging: PropTypes.shape({
    page: PropTypes.number,
    per_page: PropTypes.number,
    sort: PropTypes.string,
    direction: PropTypes.string,
    filters: PropTypes.string,
    size: PropTypes.number
  }),
  // filterProps: PropTypes.shape({
  //   anchor: Filter.propTypes.shape,
  //   columns: Filter.propTypes.columns
  // })
}

export default memo(TableComponent);