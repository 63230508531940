import React     from 'react';
import PropTypes from 'prop-types';
import cx        from 'classnames';

// utils
import createUUID from '~/utils/createUUID';
import isPresent from '~/utils/isPresent';
import isBlank from '~/utils/isBlank';
// --------------------------------------------------------------------

// material-ui components
import withStyles   from '@material-ui/core/styles/withStyles';
import InputLabel   from '@material-ui/core/InputLabel';
import FormControl  from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
// --------------------------------------------------------------------

import styles  from '../styles';

const handleDerivedStateFromProps = (state, nextProps, prevState, stateName, defaultValue) => {
  const nextState = { ...state };
  if ( isPresent(nextProps) ){
    if(nextProps !== prevState[stateName] ){
      nextState[stateName] = nextProps;
    }
  }else{
    if( isBlank(prevState[stateName]) ){
      nextState[stateName] = typeof defaultValue === 'function' ? defaultValue() : defaultValue;
    }
  }

  return nextState;
};

class InputControl extends React.Component {
  static propTypes = {
    inputComponent: PropTypes.any.isRequired,
    name:           PropTypes.string.isRequired,
    classes:        PropTypes.object.isRequired,
    label:          PropTypes.node,
    InputProps:     PropTypes.object,
    fullWidth:      PropTypes.bool,
    stateName:      PropTypes.string,
    value:          PropTypes.any,
    onChange:       PropTypes.func,
    labelShrink:    PropTypes.bool,
    required:       PropTypes.bool,
    autoFocus:      PropTypes.bool,
    autoComplete:   PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    endAdornment:   PropTypes.node,
    startAdornment: PropTypes.node,
    disabled:       PropTypes.bool,
  };

  static defaultProps = {
    fullWidth:      true,
    labelShrink:    true,
    labelComponent: InputLabel,
    InputProps:     {},
    disabled:       false,
  };

  state = {
    inputID:   null,
    stateName: null,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let nextState = {};

    nextState = handleDerivedStateFromProps(nextState, nextProps.id, prevState, 'inputID', createUUID );
    nextState = handleDerivedStateFromProps(nextState, nextProps.stateName, prevState, 'stateName', nextProps.name );

    if( isBlank(nextState) ){
      return null;
    }else{
      return nextState;
    }
  }

  handleChange = (arg1, arg2) => {
    const { onChange } = this.props;
    const { stateName } = this.state;
    if( arg1 && isPresent(arg1.nativeEvent) ){
      onChange( {[stateName]: arg1.target.value}, arg1);
    }else{
      onChange( arg1, arg2 );
    }
  };

  addEndAdornment = () => {
    const { endAdornment } = this.props;
    if( isBlank(endAdornment) ){
      return undefined;
    }

    return(
      <InputAdornment position="end">
        { endAdornment }
      </InputAdornment>
    );
  };

  addStartAdornment = () => {
    const { startAdornment } = this.props;
    if( isBlank(startAdornment) ){
      return undefined;
    }

    return(
      <InputAdornment position="start">
        { startAdornment }
      </InputAdornment>
    );
  };

  get autoComplete() {
    const { autoComplete, name } = this.props;

    if (typeof autoComplete === 'boolean')
      return autoComplete ? name : 'off';

    return autoComplete;
  }

  render(){
    const {
      classes,
      onChange,
      disabled,

      // FormControl props
      fullWidth,

      // label props
      labelComponent,
      label,
      labelShrink,

      // input props
      autoFocus,
      inputComponent,
      InputProps,
      required,
      value,
      name,
    } = this.props;

    const { inputID, stateName } = this.state;

    const LabelComponent = labelComponent;
    const InputComponent = inputComponent;

    const {
      classes: InputClasses,
      ...InputPropsRest
    } = InputProps;
    const inputType = InputComponent.inputType;

    // const isSelect = ['IntegrationSelectAsync','WithStyles(IntegrationReactSelect)'].includes( InputComponent.displayName );

    return(
      <FormControl fullWidth={fullWidth} classes={{
        root: cx(classes.formControlRoot, {[classes.formControlDisabled]: disabled})
        }}
      >
        { Boolean(label) &&
          <LabelComponent
            disabled={disabled}
            shrink={labelShrink}
            htmlFor={inputID}
            classes={{
              root:   classes.labelRoot,
              shrink: classes.labelShrink,
              formControl: cx({
                [classes.labelFormControlWYSIWYG]: inputType === 'WYSIWYG'
              }),
              disabled: cx({
                [classes.labelDisabled]: disabled
              })
            }}
            // FormLabelClasses={{
            //   root:    classes.labelFocused,
            //   focused: 'focused',
            // }}
          >
            { required &&
              <abbr data-for="default-tooltip" data-tip="Obrigatório" title="" currentitem="false">* </abbr>
            }
            {label}
          </LabelComponent>
        }
        <InputComponent
          {...InputPropsRest}
          autoFocus={autoFocus}
          autoComplete={this.autoComplete}
          disabled={disabled}
          classes={{
            input: classes.inputElement,
            disabled: cx({
              [classes.inputDisabled]: disabled
            }),
            ...InputClasses,
          }}
          state-name={stateName}
          className={cx(classes.inputRoot, !label && classes.inputWithoutLabel)}
          required={required}
          id={inputID}
          name={name}
          value={value === null ? '' : value}
          onChange={ isPresent(onChange) ? this.handleChange : undefined}
          startAdornment={ this.addStartAdornment() }
          endAdornment={ this.addEndAdornment() }
        />
      </FormControl>
    );
  }
}

export default withStyles( styles )( InputControl );
