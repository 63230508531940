import Route from '~/services/route';

export const customRecordsApi = {
  show:    new Route('/api/v1/custom_records/:sys_key/:id'),
  update:  new Route('/api/v1/custom_records/:sys_key/:id'),
  destroy: new Route('/api/v1/custom_records/:sys_key/:id'),
  create:  new Route('/api/v1/custom_records/:sys_key'),
  list:    new Route('/api/v1/custom_records/:sys_key'),
}

export const fieldsApi = {
  show:    new Route('/api/v1/fields/:id'),
  update:  new Route('/api/v1/fields/:id'),
  destroy: new Route('/api/v1/fields/:id'),
  create:  new Route('/api/v1/fields'),
  list:    new Route('/api/v1/fields'),
}

export const metadataApi = {
  show:    new Route('/api/v1/metadata/:id'),
  update:  new Route('/api/v1/metadata/:id'),
  destroy: new Route('/api/v1/metadata/:id'),
  create:  new Route('/api/v1/metadata'),
  list:    new Route('/api/v1/metadata'),
}

export const peopleApi = {
  show:    new Route('/api/v1/people/:id'),
  update:  new Route('/api/v1/people/:id'),
  destroy: new Route('/api/v1/people/:id'),
  create:  new Route('/api/v1/people'),
  list:    new Route('/api/v1/people'),
}

export const rolesApi = {
  show:    new Route('/api/v1/roles/:id'),
  update:  new Route('/api/v1/roles/:id'),
  destroy: new Route('/api/v1/roles/:id'),
  create:  new Route('/api/v1/roles'),
  list:    new Route('/api/v1/roles'),
}

export const permissionsApi = {
  show:    new Route('/api/v1/permissions/:id'),
  update:  new Route('/api/v1/permissions/:id'),
  destroy: new Route('/api/v1/permissions/:id'),
  create:  new Route('/api/v1/permissions'),
  list:    new Route('/api/v1/permissions'),
}

export const usersApi = {
  show:    new Route('/api/v1/admin/users/:id'),
  update:  new Route('/api/v1/admin/users/:id'),
  destroy: new Route('/api/v1/admin/users/:id'),
  create:  new Route('/api/v1/admin/users'),
  list:    new Route('/api/v1/admin/users'),
}

export const authApi = {
  getToken: new Route('/oauth/token'),
  usersPassword: new Route('/api/v1/users/password')
}

export const fileUploadsApi = {
  list:   new Route('/api/v1/file_uploads'),
  update: new Route('/api/v1/file_uploads/:uuid'),
  upload: new Route('/api/v1/file_uploads'),
  delete: new Route('/api/v1/file_uploads/:uuid'),
}

export const utilsApi = {
  searchCep: new Route('/api/v1/utils/search_cep/:cep')
}

export const citiesApi = {
  searchCities: new Route('/api/v1/cities'),
  show: new Route('/api/v1/cities/:id'),
}

export const statesApi = {
  search: new Route('/api/v1/states'),
  show: new Route('/api/v1/states/:id'),
}

export const animalsApi = {
  show:    new Route('/api/v1/animals/:id'),
  update:  new Route('/api/v1/animals/:id'),
  destroy: new Route('/api/v1/animals/:id'),
  create:  new Route('/api/v1/animals'),
  list:    new Route('/api/v1/animals'),
}

export const dataImportsApi = {
  show:    new Route('/api/v1/data_imports/:id'),
  update:  new Route('/api/v1/data_imports/:id'),
  destroy: new Route('/api/v1/data_imports/:id'),
  create:  new Route('/api/v1/data_imports'),
  list:    new Route('/api/v1/data_imports'),
}

export const calcSettingsApi = {
  show: new Route('/api/v1/admin/calc_settings'),
  save: new Route('/api/v1/admin/calc_settings')
}


export const indicatorWeightsApi = {
  show: new Route('/api/v1/admin/indicator_weights'),
  save: new Route('/api/v1/admin/indicator_weights')
}

export const rankingsApi = {
  self: new Route('/api/v1/ranking/:breed')
}

export const farmsApi = {
  show:    new Route('/api/v1/farms/:id'),
  update:  new Route('/api/v1/farms/:id'),
  destroy: new Route('/api/v1/farms/:id'),
  create:  new Route('/api/v1/farms'),
  list:    new Route('/api/v1/farms'),
  search:  new Route('/api/v1/search/farms'),
}
