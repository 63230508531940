import RecordBase from './Base';
import archiveSchema from './schema/archiveSchema';

export default class ArchiveRecord extends RecordBase {
  static defaultAttributes = archiveSchema.defaultAttributes;

  static _attributeNames = null;

  static get attributeNames() { return archiveSchema.attributeNames }


  static buildFromFile(file){
    return new ArchiveRecord({
      file,
      filename:  file.name,
      file_size: file.size,
      icon:      URL.createObjectURL(file),
      progress:  0,
      isNew:     true
    })
  }

  static getNewFiles(mapFiles){
    return mapFiles
      .filter( e => !e.get('_destroy') && (e.get('isNew') || e.get('fromGallery')) )
      .toArray()
      .map((v) => v[1].id )
  }

  static getDeleteFiles(mapFiles){
    return mapFiles
      .filter( e => e.get('_destroy') && !e.get('isNew') && !e.get('fromGallery') )
      .toArray()
      .map((v) => v[1].id )
  }

  toParams() {
    return {
      data:{
        attributes:{
          description: this.get('description')
        }
      }
    };
  }
}
