import React, { useState, useCallback } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputControl from '~/components/Input/InputControl';
import InputSelectAsync from '~/components/Input/InputSelectAsync';
import InputCEP from '~/components/Input/InputCEP';
import FullScreenLoading from '~/components/FullScreenLoading';

import Request from '~/services/request';
import {
  statesApi,
  citiesApi,
  utilsApi
} from '~/routes/api';
import AddressRecord from '~/records/AddressRecord';

const attrName = (attr) => AddressRecord.hummanAttributeName(attr);

function Address ({
  record,
  onChangeAddress,
  errors = {},
  disableForm
}) {
  const [loading, setLoading] = useState(false);

  const handleSearchCEPByKeyEvent = useCallback(
    async (event) => {
      const KEY_CODE_ENTER = 13;

      if (event.keyCode === KEY_CODE_ENTER) {
        event.preventDefault()
        handleSearchCEP();
      }
    },
    [record]
  );

  const handleSearchCEP = useCallback(
    async () => {
      const typedCep = record.get('address')?.zip_code;
      const CEP_IS_VALID = typedCep?.length === 9;

      if (CEP_IS_VALID) {
        try {
          setLoading(true);

          const response = await Request.get(
            utilsApi.searchCep.build({cep: typedCep})
          );

          const cep = response.data?.data;
          if (cep) {
            const { city, district, state, street } = cep;
            onChangeAddress({ city, district, state, street });
          }

          setLoading(false);
        } catch(err) {
          setLoading(false);
        }
      }
    },
    [record]
  );

  const getCitiesPath = useCallback(() => {
    return `${citiesApi.searchCities.build()}?sort=cities.name&direction=asc`;
  }, []);

  const getStatesPath = useCallback(() => {
    return `${statesApi.search.build()}?sort=name&direction=asc`;
  }, []);

  return (
    <Card style={{ pointerEvents: disableForm ? 'none' : 'auto' }}>
      <CardContent>
        <GridContainer>
          <Col md={4}>
            <InputControl
              name="zip_code"
              label={attrName('zip_code')}
              value={record.get('address')?.zip_code || null}
              inputComponent={InputCEP}
              endAdornment={(
                <IconButton onClick={handleSearchCEP}>
                  <SearchIcon />
                </IconButton>
              )}
              InputProps={{
                maxLength: 8,
                onKeyDown: handleSearchCEPByKeyEvent,
                // error: !!errors['name']
              }}
              onChange={onChangeAddress}
            />
          </Col>
          <Col md={4}>
            <InputControl
              name="street"
              label={attrName('street')}
              value={record.get('address')?.street || null}
              inputComponent={Input}
              // InputProps={{ error: !!errors['name'] }}
              onChange={onChangeAddress}
            />
          </Col>
          <Col md={4}>
            <InputControl
              name="building_number"
              label={attrName('building_number')}
              value={record.get('address')?.building_number || null}
              inputComponent={Input}
              InputProps={{
                type: 'number'
                // error: !!errors['name']
              }}
              onChange={onChangeAddress}
            />
          </Col>
        </GridContainer>

        <GridContainer>
          <Col md={4}>
            <InputControl
              name="district"
              label={attrName('district')}
              value={record.get('address')?.district || null}
              inputComponent={Input}
              // InputProps={{ error: !!errors['name'] }}
              onChange={onChangeAddress}
            />
          </Col>
          <Col md={4}>
            <InputControl
              name="city"
              stateName={'city'}
              label={attrName('city')}
              value={record.get('address')?.city || null}
              inputComponent={InputSelectAsync}
              onChange={onChangeAddress}
              InputProps={{
                fetchUrl: getCitiesPath(),
                createEnabled: false,
                error: !!errors['address.city']
              }}
            />
          </Col>
          <Col md={4}>
            <InputControl
              name="state"
              stateName={'state'}
              label={attrName('state')}
              value={record.get('address')?.state || null}
              inputComponent={InputSelectAsync}
              onChange={onChangeAddress}
              InputProps={{
                fetchUrl: getStatesPath(),
                createEnabled: false,
                error: !!errors['address.city']
              }}
            />
          </Col>
        </GridContainer>

        <InputControl
          name="complement"
          label={attrName('complement')}
          value={record.get('address')?.complement || null}
          inputComponent={Input}
          // InputProps={{ error: !!errors['name'] }}
          onChange={onChangeAddress}
        />
      </CardContent>

      {loading && <FullScreenLoading loadingText="Buscando CEP" />}
    </Card>
  );
}

export default Address;
