import ActionsCrudConstructor from '~/store/utils/ActionsCrudConstructor';
import createReducer from '~/store/utils/createReducer';
import { takeLatest, all } from 'redux-saga/effects';
import {
  findRecordRequest,
  destroyRecordRequest,
  updateRecordRequest,
  createRecordRequest,
  fetchListRecordsRequest,
} from '~/services/fetchApi/animals';
import {
  initLoading,
  setErrors,
  resetState,
  fetchStart,
  fetchDone,
  setCurrent,
  setChangeList,
  fetchListStart,
  fetchListDone
} from '~/store/utils/reducerFns';
import { animalsRoute } from '~/routes'

/* =================================================
  REDUX TYPES
================================================= */
export const ANIMALS_SET_CURRENT = '@App/animals/ANIMALS_SET_CURRENT';
export const ANIMALS_FETCH_START = '@App/animals/ANIMALS_FETCH_START';
export const ANIMALS_FETCH_DONE  = '@App/animals/ANIMALS_FETCH_DONE';
export const ANIMALS_FETCH_LIST_START = '@App/animals/ANIMALS_FETCH_LIST_START';
export const ANIMALS_FETCH_LIST_DONE = '@App/animals/ANIMALS_FETCH_LIST_DONE';
export const ANIMALS_SET_ERRORS = '@App/animals/ANIMALS_SET_ERRORS';
export const CLEAR_STATE = '@App/animals/CLEAR_STATE';
export const FIND_PERSON_REQUEST = '@App/animals/FIND_PERSON_REQUEST';
export const DESTROY_ANIMALS_REQUEST = '@App/animals/DESTROY_ANIMALS_REQUEST';
export const UPDATE_ANIMALS_REQUEST = '@App/animals/UPDATE_ANIMALS_REQUEST';
export const CREATE_ANIMALS_REQUEST = '@App/animals/CREATE_ANIMALS_REQUEST';
export const FETCH_ANIMALS_REQUEST  = '@App/animals/FETCH_ANIMALS_REQUEST';
export const ANIMALS_CHANGE_LIST  = '@App/animals/ANIMALS_CHANGE_LIST';

/* =================================================
  REDUX REDUCER
================================================= */

const INITIAL_STATE = {
  current:   undefined,
  data:      [],
  errors:    [],
  isLoading: false,
  listIsLoading: false,
  totalData: 0,
};

export default createReducer(
  INITIAL_STATE,
  {
    [CLEAR_STATE]: resetState,
    [ANIMALS_FETCH_START]: fetchStart,
    [ANIMALS_FETCH_DONE]:  fetchDone,
    [ANIMALS_FETCH_LIST_START]: fetchListStart,
    [ANIMALS_FETCH_LIST_DONE]: fetchListDone,
    [ANIMALS_SET_ERRORS]: setErrors,
    [ANIMALS_SET_CURRENT]: setCurrent,
    [DESTROY_ANIMALS_REQUEST]: initLoading,
    [CREATE_ANIMALS_REQUEST]: initLoading,
    [UPDATE_ANIMALS_REQUEST]: initLoading,
    [ANIMALS_CHANGE_LIST]: setChangeList,
  }
)

/* =================================================
  REDUX ACTIONS
================================================= */
export function clearState() { return { type: CLEAR_STATE } } // Utilizado ao desmontar os componentes
export function findRecord(id, params={}) {
  return {
    type: FIND_PERSON_REQUEST,
    payload: { id, params }
  }
}
export function destroyRecord(id, callbackSuccess) {
  return {
    type: DESTROY_ANIMALS_REQUEST,
    payload: { id, callbackSuccess }
  }
}

export function updateRecord(id, data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: UPDATE_ANIMALS_REQUEST,
    payload: { id, data, params, callbackSuccess, callbackFailed }
  }
}
export function createRecord(data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: CREATE_ANIMALS_REQUEST,
    payload: { data, params, callbackSuccess, callbackFailed }
  }
}

export function fetchRecords(params='', requestSource=undefined, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: FETCH_ANIMALS_REQUEST,
    payload: { params, requestSource, callbackSuccess, callbackFailed }
  }
}

/* =================================================
  SAGA ACTIONS
================================================= */
const crudActions = new ActionsCrudConstructor({
  reducerKey: 'animals',

  routes: {
    list: () => animalsRoute.list.build(),
    show: (id) => animalsRoute.show.build({id}),
    new:  (id) => animalsRoute.new.build({id})
  },

  apiRequests: {
    findRecordApi:    findRecordRequest,
    destroyRecordApi: destroyRecordRequest,
    updateRecordApi:  updateRecordRequest,
    createRecordApi:  createRecordRequest,
    fetchListApi:     fetchListRecordsRequest,
  },

  actionTypes: {
    FETCH_START:      ANIMALS_FETCH_START,
    FETCH_DONE:       ANIMALS_FETCH_DONE,
    FETCH_LIST_START: ANIMALS_FETCH_LIST_START,
    FETCH_LIST_DONE:  ANIMALS_FETCH_LIST_DONE,
    CHANGE_LIST:      ANIMALS_CHANGE_LIST,
    CHANGE_CURRENT:   ANIMALS_SET_CURRENT,
    SET_ERRORS:       ANIMALS_SET_ERRORS,
  }
});

export const saga = all([
  takeLatest(FIND_PERSON_REQUEST, crudActions.showAction),
  takeLatest(DESTROY_ANIMALS_REQUEST, crudActions.destroyAction),
  takeLatest(UPDATE_ANIMALS_REQUEST, crudActions.updateAction),
  takeLatest(CREATE_ANIMALS_REQUEST, crudActions.createAction),
  takeLatest(FETCH_ANIMALS_REQUEST, crudActions.fetchListAction),
]);