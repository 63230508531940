import { all } from 'redux-saga/effects';

import {saga as forgotPassword} from './modules/forgotPassword';
import {saga as resetPassword} from './modules/resetPassword';
import {saga as userProfile} from './modules/userProfile';
import {saga as auth} from './modules/auth';
import {saga as crud} from './modules/crud';
import {saga as fields} from './modules/fields';
import {saga as animals} from './modules/animals';
import {saga as metadata} from './modules/metadata';
import {saga as customRecords} from './modules/customRecords';
import {saga as roles} from './modules/roles';
import {saga as users} from './modules/users';
import {saga as farms} from './modules/farms';
import {saga as dataImports} from './modules/dataImports';

export default function* rootSaga() {
  return yield all([
    crud,
    auth,
    userProfile,
    animals,
    metadata,
    roles,
    forgotPassword,
    resetPassword,
    users,
    fields,
    customRecords,
    dataImports,
    farms
  ]);
}
