import React, { useCallback } from 'react';

import { usersRoute } from '~/routes'
import CrudLayoutList from '~/containers/CrudLayoutList';
import userSchema from '~/records/schema/userSchema';
import { useDispatch, connect } from 'react-redux';
import { fetchRecords, destroyRecord } from '~/store/modules/users';

function List({isLoading, rows, total}) {
  const dispatch = useDispatch();

  const handleFetch = useCallback(
    async (params, requestSource) => {
      dispatch( fetchRecords(params, requestSource) )
    },
    [],
  )

  const onDelete = useCallback(
    async (row) => {
      dispatch( destroyRecord(row.id) )
    },
    []
  )

  return (
    <CrudLayoutList
      breadcrumbs={[{ text: 'Usuários' }]}
      permissionKeyCode="User"
      routes={{
        newPath:  ()  =>  usersRoute.new.build(),
        showPath: (id) => usersRoute.show.build({id}),
        editPath: (id) => usersRoute.edit.build({id})
      }}
      tableProps={{
        isLoading,
        rows,
        total,
        handleFetch,
        onDelete,
        schema: userSchema,
        actions: ['show', 'edit', 'delete'],
        permissionKeyCode: 'User',
        filterProps: {
          columns: userSchema.filterColumns
        }
      }}
    />
  );
}

const mapStateToProps = state => ({
  isLoading: state.users.listIsLoading,
  rows: state.users.data,
  total: state.users.totalData,
});

export default connect(mapStateToProps)(List);