import { combineReducers } from 'redux';

import crud from './modules/crud';
import notifications from './modules/notifications';
import forgotPassword from './modules/forgotPassword';
import resetPassword from './modules/resetPassword';
import userProfile from './modules/userProfile';
import auth from './modules/auth';
import users from './modules/users';
import fields from './modules/fields';
import animals from './modules/animals';
import roles from './modules/roles';
import metadata from './modules/metadata';
import customRecords from './modules/customRecords';
import dataImports from './modules/dataImports';
import farms from './modules/farms';

export default combineReducers({
  crud,
  notifications,
  auth,
  userProfile,
  animals,
  roles,
  metadata,
  forgotPassword,
  resetPassword,
  users,
  fields,
  customRecords,
  dataImports,
  farms
});
