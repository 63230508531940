import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { findRecord, destroyRecord, clearState } from '~/store/modules/animals';
import { animalsRoute } from '~/routes'

import LayoutShow from '~/components/LayoutShow';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';
import InputShow from '~/components/Input/InputShow';
import AnimalRecord from '~/records/AnimalRecord';
import genderEnum from '~/enums/genderEnum';

const attrName = (attr) => AnimalRecord.hummanAttributeName(attr);

const MAP_COLS = [
  'udder_posterior_ligament', 'udder_anterior_ligament', 'udder_depth',
  'udder_width', 'udder_height', 'udder_posterior_tits', 'udder_anterior_tits', 'udder_total',
  'solid_compound_fat_vol', 'solid_compound_protein_vol',
  'solid_compound_fat_percent', 'solid_compound_protein_percent',
  'solid_compound_somatic_cells', 'solid_compound_total',
  'fertility_idx_ccr', 'fertility_idx_hcr',
  'fertility_idx_dpr', 'fertility_idx_total',
  'milk_vol',
  'composite_paws_legs_side_view',
  'composite_paws_legs_behind_view',
  'composite_paws_legs_hull_angle',
  'composite_paws_legs_total',
  'body_compound_stature',
  'body_compound_strength',
  'body_compound_depth',
  'body_compound_milk',
  'body_compound_croup_angle',
  'body_compound_croup_width',
  'body_compound_total',
]

function Show({ animal, isLoading }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const onDelete = useCallback(
    () => {
      dispatch( destroyRecord(id) )
    },
    [id]
  )

  useEffect(() => {
    dispatch(findRecord(id, {include: 'farm'}));

    return () => dispatch(clearState());
  }, []);

  return (
    <LayoutShow
      breadcrumbs={[
        { path: animalsRoute.list.path, text: 'Animais' },
        { text: 'Exibindo Animal' },
      ]}
      loading={isLoading}
      crudActionsProps={{
        onDelete,
        routeNewRegister:   animalsRoute.new.build(),
        routeCloneRegister: animalsRoute.clone.build({ id }),
        routeEditRegister:  animalsRoute.edit.build({ id }),
        permissionKeyCode:  'Animal'
      }}
    >
      <Card>
        <CardContent>
          <GridContainer>
            <Col sm={2}>
              <InputShow
                label={attrName('code')}
                value={animal?.code}
              />
            </Col>
            <Col sm={2}>
              <InputShow
                label={attrName('breed')}
                value={animal?.breed}
              />
            </Col>
            <Col sm={4}>
              <InputShow
                label={attrName('name')}
                value={animal?.name}
              />
            </Col>
            <Col sm={4}>
              <InputShow
                label={attrName('farm')}
                value={animal?.farm?.name}
              />
            </Col>
            <Col sm={3}>
              <InputShow
                label="Tipo"
                type="string"
                value={ animal ? genderEnum().objects()[animal.gender] : ''}
              />
            </Col>
            <Col sm={3}>
              <InputShow
                label={attrName('ipg_final')}
                value={animal?.ipg_final}
                type="float"
                InputProps={{style: {color: 'red'}}}
              />
            </Col>
          </GridContainer>
          <GridContainer>
            <Col sm={4}>
              <InputShow
                label={attrName('father_code')}
                value={animal?.father_code}
              />
            </Col>
            <Col sm={4}>
              <InputShow
                label={attrName('mother_code')}
                value={animal?.mother_code}
              />
            </Col>
            <Col sm={4}>
              <InputShow
                label={attrName('updated_at')}
                value={animal?.updated_at}
                type="datetime"
              />
            </Col>
          </GridContainer>
          { !!animal &&
            <GridContainer>
              { MAP_COLS.map( (col) =>
                <Col key={col} sm={3}>
                  <InputShow
                    label={attrName(col)}
                    value={animal[col]}
                    type="float"
                  />
                </Col>
              ) }
            </GridContainer>
          }
        </CardContent>
      </Card>
    </LayoutShow>
  );
}

const mapStateToProps = state => ({
  isLoading: state.animals.isLoading,
  animal: state.animals.current,
});

export default connect(mapStateToProps)(Show);