import ActionsCrudConstructor from '~/store/utils/ActionsCrudConstructor';
import createReducer from '~/store/utils/createReducer';
import { takeLatest, all } from 'redux-saga/effects';
import {
  findRecordRequest,
  destroyRecordRequest,
  updateRecordRequest,
  createRecordRequest,
  fetchListRecordsRequest,
} from '~/services/fetchApi/dataImports';
import {
  initLoading,
  setErrors,
  resetState,
  fetchStart,
  fetchDone,
  setCurrent,
  setChangeList,
  fetchListStart,
  fetchListDone
} from '~/store/utils/reducerFns';
import { dataImportsRoute } from '~/routes'

/* =================================================
  REDUX TYPES
================================================= */
export const DATA_IMPORTS_SET_CURRENT = '@App/dataImports/DATA_IMPORTS_SET_CURRENT';
export const DATA_IMPORTS_FETCH_START = '@App/dataImports/DATA_IMPORTS_FETCH_START';
export const DATA_IMPORTS_FETCH_DONE  = '@App/dataImports/DATA_IMPORTS_FETCH_DONE';
export const DATA_IMPORTS_FETCH_LIST_START = '@App/dataImports/DATA_IMPORTS_FETCH_LIST_START';
export const DATA_IMPORTS_FETCH_LIST_DONE = '@App/dataImports/DATA_IMPORTS_FETCH_LIST_DONE';
export const DATA_IMPORTS_SET_ERRORS = '@App/dataImports/DATA_IMPORTS_SET_ERRORS';
export const CLEAR_STATE = '@App/dataImports/CLEAR_STATE';
export const FIND_PERSON_REQUEST = '@App/dataImports/FIND_PERSON_REQUEST';
export const DESTROY_DATA_IMPORTS_REQUEST = '@App/dataImports/DESTROY_DATA_IMPORTS_REQUEST';
export const UPDATE_DATA_IMPORTS_REQUEST = '@App/dataImports/UPDATE_DATA_IMPORTS_REQUEST';
export const CREATE_DATA_IMPORTS_REQUEST = '@App/dataImports/CREATE_DATA_IMPORTS_REQUEST';
export const FETCH_DATA_IMPORTS_REQUEST  = '@App/dataImports/FETCH_DATA_IMPORTS_REQUEST';
export const DATA_IMPORTS_CHANGE_LIST  = '@App/dataImports/DATA_IMPORTS_CHANGE_LIST';

/* =================================================
  REDUX REDUCER
================================================= */

const INITIAL_STATE = {
  current:   undefined,
  data:      [],
  errors:    [],
  isLoading: false,
  listIsLoading: false,
  totalData: 0,
};

export default createReducer(
  INITIAL_STATE,
  {
    [CLEAR_STATE]: resetState,
    [DATA_IMPORTS_FETCH_START]: fetchStart,
    [DATA_IMPORTS_FETCH_DONE]:  fetchDone,
    [DATA_IMPORTS_FETCH_LIST_START]: fetchListStart,
    [DATA_IMPORTS_FETCH_LIST_DONE]: fetchListDone,
    [DATA_IMPORTS_SET_ERRORS]: setErrors,
    [DATA_IMPORTS_SET_CURRENT]: setCurrent,
    [DESTROY_DATA_IMPORTS_REQUEST]: initLoading,
    [CREATE_DATA_IMPORTS_REQUEST]: initLoading,
    [UPDATE_DATA_IMPORTS_REQUEST]: initLoading,
    [DATA_IMPORTS_CHANGE_LIST]: setChangeList,
  }
)

/* =================================================
  REDUX ACTIONS
================================================= */
export function clearState() { return { type: CLEAR_STATE } } // Utilizado ao desmontar os componentes
export function findRecord(id, params={}) {
  return {
    type: FIND_PERSON_REQUEST,
    payload: { id, params }
  }
}
export function destroyRecord(id, callbackSuccess) {
  return {
    type: DESTROY_DATA_IMPORTS_REQUEST,
    payload: { id, callbackSuccess }
  }
}

export function updateRecord(id, data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: UPDATE_DATA_IMPORTS_REQUEST,
    payload: { id, data, params, callbackSuccess, callbackFailed }
  }
}
export function createRecord(data, params={}, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: CREATE_DATA_IMPORTS_REQUEST,
    payload: { data, params, callbackSuccess, callbackFailed }
  }
}

export function fetchRecords(params='', requestSource=undefined, callbackSuccess=undefined, callbackFailed=undefined) {
  return {
    type: FETCH_DATA_IMPORTS_REQUEST,
    payload: { params, requestSource, callbackSuccess, callbackFailed }
  }
}

/* =================================================
  SAGA ACTIONS
================================================= */
const crudActions = new ActionsCrudConstructor({
  reducerKey: 'dataImports',

  routes: {
    list: () => dataImportsRoute.list.build(),
    show: (id) => dataImportsRoute.show.build({id}),
    new:  (id) => dataImportsRoute.new.build({id})
  },

  apiRequests: {
    findRecordApi:    findRecordRequest,
    destroyRecordApi: destroyRecordRequest,
    updateRecordApi:  updateRecordRequest,
    createRecordApi:  createRecordRequest,
    fetchListApi:     fetchListRecordsRequest,
  },

  actionTypes: {
    FETCH_START:      DATA_IMPORTS_FETCH_START,
    FETCH_DONE:       DATA_IMPORTS_FETCH_DONE,
    FETCH_LIST_START: DATA_IMPORTS_FETCH_LIST_START,
    FETCH_LIST_DONE:  DATA_IMPORTS_FETCH_LIST_DONE,
    CHANGE_LIST:      DATA_IMPORTS_CHANGE_LIST,
    CHANGE_CURRENT:   DATA_IMPORTS_SET_CURRENT,
    SET_ERRORS:       DATA_IMPORTS_SET_ERRORS,
  }
});

export const saga = all([
  takeLatest(FIND_PERSON_REQUEST, crudActions.showAction),
  takeLatest(DESTROY_DATA_IMPORTS_REQUEST, crudActions.destroyAction),
  takeLatest(UPDATE_DATA_IMPORTS_REQUEST, crudActions.updateAction),
  takeLatest(CREATE_DATA_IMPORTS_REQUEST, crudActions.createAction),
  takeLatest(FETCH_DATA_IMPORTS_REQUEST, crudActions.fetchListAction),
]);