/*eslint prefer-const: "off"*/
'use strict';

/* Método que converte um objeto em string para requisições
  Examples
    queryStringParser({foo: 'bar', bar: 'ok'})
    => 'foo=bar&bar=ok'
*/

export default function queryStringParser(params){
  if(typeof params === 'string'){ return params.includes('?') ? params.replace('?', '') : params }

	return Object.keys(params)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');
}