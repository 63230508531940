import SchemaBuilder from './SchemaBuilder';

const columns = {
  id:          { type: 'integer',  title: 'ID' },
  name:        { type: 'string',   title: 'Nome'},
  created_at:  { type: 'datetime', default: '', title: 'Criado em' },
  updated_at:  { type: 'datetime', default: '', title: 'Atualizado em', hidden: true, }
};

const farmSchema = new SchemaBuilder( columns );

export default farmSchema;