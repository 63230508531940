import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import InputControl from '~/components/Input/InputControl';
import InputNumber from '~/components/Input/InputNumber';
import InputSelect from '~/components/Input/InputSelect';
import InputSelectAsync from '~/components/Input/InputSelectAsync';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';


import AnimalRecord from '~/records/AnimalRecord';
import genderEnum from '~/enums/genderEnum';
import { farmAsyncFetch } from '~/services/selectFetch/farmsFetch';

const attrName = (attr) => AnimalRecord.hummanAttributeName(attr);

const dividerStyle = {marginTop: '20px', marginBottom: '10px'};

export default function FormFields({ record, onChange}) {
  return (
    <Card>
      <CardContent>
        <GridContainer>
          <Col sm={2}>
            <InputControl
              autoFocus
              required
              name="code"
              label={attrName('code')}
              value={record.get('code')}
              type="text"
              inputComponent={Input}
              onChange={onChange}
            />
          </Col>
          <Col sm={2}>
            <InputControl
              required
              name="breed"
              label={attrName('breed')}
              value={record.get('breed')}
              type="text"
              inputComponent={InputSelect}
              onChange={onChange}
              InputProps={{
                options: [
                  { id: 'JERSEY', name: 'JERSEY' },
                  { id: 'HOLANDÊS', name: 'HOLANDÊS' },
                ],
                isClearable: false
              }}
            />
          </Col>
          <Col sm={4}>
            <InputControl
              required
              name="name"
              label={attrName('name')}
              value={record.get('name')}
              type="text"
              inputComponent={Input}
              onChange={onChange}
            />
          </Col>
          <Col sm={4}>
            <InputControl
              required
              name="farm"
              label={attrName('farm')}
              value={record.get('farm')}
              type="text"
              inputComponent={InputSelectAsync}
              onChange={onChange}
              InputProps={{ handleFetch: farmAsyncFetch }}
            />
          </Col>
          <Col sm={2}>
            <InputControl
              required
              value={record.get('gender')}
              name={'gender'}
              label={attrName('gender')}
              inputComponent={InputSelect}
              onChange={onChange}
              InputProps={{
                options: genderEnum().array,
                isClearable: false
              }}
            />
          </Col>
          <Col sm={4}>
            <InputControl
              name="father_code"
              label={attrName('father_code')}
              value={record.get('father_code')}
              type="text"
              inputComponent={Input}
              onChange={onChange}
            />
          </Col>
          <Col sm={4}>
            <InputControl
              name="mother_code"
              label={attrName('mother_code')}
              value={record.get('mother_code')}
              type="text"
              inputComponent={Input}
              onChange={onChange}
            />
          </Col>
          <Col sm={2}>
            <InputControl
              required
              name="milk_vol"
              label={attrName('milk_vol')}
              value={record.get('milk_vol')}
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
        </GridContainer>

        <Divider style={dividerStyle}/>
        <Typography variant="subtitle1">Úbere</Typography>
        <GridContainer>
          <Col sm={4}>
            <InputControl
              required
              name="udder_posterior_ligament"
              label={attrName('udder_posterior_ligament')}
              value={record.get('udder_posterior_ligament')}
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
          <Col sm={4}>
            <InputControl
              required
              name="udder_anterior_ligament"
              label={attrName('udder_anterior_ligament')}
              value={record.get('udder_anterior_ligament')}
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
          <Col sm={4}>
            <InputControl
              required
              name="udder_depth"
              label={attrName('udder_depth')}
              value={record.get('udder_depth')}
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
          <Col sm={4}>
            <InputControl
              required
              name="udder_width"
              label={attrName('udder_width')}
              value={record.get('udder_width')}
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
          <Col sm={4}>
            <InputControl
              required
              name="udder_height"
              label={attrName('udder_height')}
              value={record.get('udder_height')}
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
          <Col sm={4}>
            <InputControl
              required
              name="udder_anterior_tits"
              label={attrName('udder_anterior_tits')}
              value={record.get('udder_anterior_tits')}
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
          <Col sm={4}>
            <InputControl
              required
              name="udder_posterior_tits"
              label={attrName('udder_posterior_tits')}
              value={record.get('udder_posterior_tits')}
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
        </GridContainer>

        <Divider style={dividerStyle}/>
        <Typography variant="subtitle1">Composto corporal</Typography>
        <GridContainer>
          <Col sm={4}>
            <InputControl
              required
              name="body_compound_stature"
              label={attrName('body_compound_stature')}
              value={record.get('body_compound_stature')}
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
          <Col sm={4}>
            <InputControl
              required
              name="body_compound_strength"
              label={attrName('body_compound_strength')}
              value={record.get('body_compound_strength')}
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
          <Col sm={4}>
            <InputControl
              required
              name="body_compound_depth"
              label={attrName('body_compound_depth')}
              value={record.get('body_compound_depth')}
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
          <Col sm={4}>
            <InputControl
              required
              name="body_compound_milk"
              label={attrName('body_compound_milk')}
              value={record.get('body_compound_milk')}
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
          <Col sm={4}>
            <InputControl
              required
              name="body_compound_croup_angle"
              label={attrName('body_compound_croup_angle')}
              value={record.get('body_compound_croup_angle')}
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
          <Col sm={4}>
            <InputControl
              required
              name="body_compound_croup_width"
              label={attrName('body_compound_croup_width')}
              value={record.get('body_compound_croup_width')}
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
        </GridContainer>

        <Divider style={dividerStyle}/>
        <Typography variant="subtitle1">Compostos de patas e pernas</Typography>
        <GridContainer>
          <Col sm={4}>
            <InputControl
              required
              name="composite_paws_legs_side_view"
              label={attrName('composite_paws_legs_side_view')}
              value={record.get('composite_paws_legs_side_view')}
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
          <Col sm={4}>
            <InputControl
              required
              name="composite_paws_legs_behind_view"
              label={attrName('composite_paws_legs_behind_view')}
              value={record.get('composite_paws_legs_behind_view')}
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
          <Col sm={4}>
            <InputControl
              required
              name="composite_paws_legs_hull_angle"
              label={attrName('composite_paws_legs_hull_angle')}
              value={record.get('composite_paws_legs_hull_angle')}
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
        </GridContainer>

        <Divider style={dividerStyle}/>
        <Typography variant="subtitle1">Compostos Sólidos</Typography>
        <GridContainer>
          <Col sm={3}>
            <InputControl
              required
              name="solid_compound_fat_vol"
              label={attrName('solid_compound_fat_vol')}
              value={record.get('solid_compound_fat_vol')}
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
          <Col sm={3}>
            <InputControl
              required
              name="solid_compound_protein_vol"
              label={attrName('solid_compound_protein_vol')}
              value={record.get('solid_compound_protein_vol')}
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
          <Col sm={3}>
            <InputControl
              required
              name="solid_compound_fat_percent"
              label={attrName('solid_compound_fat_percent')}
              value={record.get('solid_compound_fat_percent')}
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
          <Col sm={3}>
            <InputControl
              required
              name="solid_compound_protein_percent"
              label={attrName('solid_compound_protein_percent')}
              value={record.get('solid_compound_protein_percent')}
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
          <Col sm={3}>
            <InputControl
              required
              name="solid_compound_somatic_cells"
              label={attrName('solid_compound_somatic_cells')}
              value={record.get('solid_compound_somatic_cells')}
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
        </GridContainer>

        <Divider style={dividerStyle}/>
        <Typography variant="subtitle1">Índice de fertilidade</Typography>
        <GridContainer>
          <Col sm={4}>
            <InputControl
              required
              name="fertility_idx_ccr"
              label={attrName('fertility_idx_ccr')}
              value={record.get('fertility_idx_ccr')}
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
          <Col sm={4}>
            <InputControl
              required
              name="fertility_idx_hcr"
              label={attrName('fertility_idx_hcr')}
              value={record.get('fertility_idx_hcr')}
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
          <Col sm={4}>
            <InputControl
              required
              name="fertility_idx_dpr"
              label={attrName('fertility_idx_dpr')}
              value={record.get('fertility_idx_dpr')}
              inputComponent={InputNumber}
              onChange={onChange}
            />
          </Col>
        </GridContainer>
      </CardContent>
    </Card>
  );
}