import Request from '~/services/request';
import { farmsApi } from '~/routes/api';

export const farmAsyncFetch = (inputValue, page, doneCallback) => {
  return Request.get(`${farmsApi.search.build()}?sort=name&direction=asc&qs=${inputValue}&page=${page}&per_page=50`).then(resp => {
    console.log("resp.data = ", resp.data)
    doneCallback (
      {
        total_count: resp.data.meta.total,
        items: resp.data.data.map( e => ({
          id: e.id,
          name: `${e.name} {${e.id}}`
        }))
      }
    )
  })
  .catch(err => err);
};

export const farmAsyncShow =  (id) => {
  return Request.get(`${farmsApi.show.build({id})}?adapter=json`).then(resp => {
    if(resp.data && resp.data.farm){
      const farm = resp.data.farm;
      return {
        ...farm,
        name: `${farm.name}`
      }
    }
    return { id: null, name: 'Não localizado' };
  })
  .catch(err => err);
}