import RecordBase from './Base';
import animalSchema from './schema/animalSchema';
import FarmRecord from './FarmRecord'

export default class AnimalRecord extends RecordBase {
  static defaultAttributes = animalSchema.defaultAttributes;
  static _attributeNames = null;
  static get attributeNames() {
    if(!this._attributeNames){
      this._attributeNames = animalSchema.attributeNames;
    }
    return this._attributeNames;
  }
  static belongsTo = { farm: FarmRecord };

  toParams(extra={}) {
    return {
      data:{
        attributes: {
          ...this.toObject(),
          farm_id: this.get('farm')?.get('id'),
          ...extra
        }
      }
    };
  }
}