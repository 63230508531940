import React, { useCallback } from 'react';

import { animalsRoute } from '~/routes'
import CrudLayoutList from '~/containers/CrudLayoutList';
import animalSchema from '~/records/schema/animalSchema';
import { useDispatch, connect } from 'react-redux';
import { fetchRecords, destroyRecord } from '~/store/modules/animals';

function getBullName(bull){
  if(!bull){ return '' }
  return `${bull.code} - ${bull.name}`
}

function List({isLoading, rows, total}) {
  const dispatch = useDispatch();

  const handleFetch = useCallback(
    async (params, requestSource) => {
      dispatch( fetchRecords(`${params}&adapter=json&include=father-mother-farm`, requestSource) )
    },
    [],
  )

  const onDelete = useCallback(
    async (row) => {
      dispatch( destroyRecord(row.id) )
    },
    []
  )

  return (
    <CrudLayoutList
      breadcrumbs={[{ text: 'Animais' }]}
      permissionKeyCode="Animal"
      routes={{
        newPath:  ()  =>  animalsRoute.new.build(),
        showPath: (id) => animalsRoute.show.build({id}),
        editPath: (id) => animalsRoute.edit.build({id})
      }}
      tableProps={{
        isLoading,
        rows,
        total,
        handleFetch,
        onDelete,
        schema: animalSchema,
        actions: ['show', 'edit', 'delete'],
        permissionKeyCode: 'Animal',
        columnsOverride: [
          {
            name: 'farm',
            formatterComponent: (_, row) => (
              <div>{row.farm?.name}</div>
            )
          },
          {
            name: 'father',
            formatterComponent: (_, row) => (
              <div>{getBullName(row.father)}</div>
            )
          },
          {
            name: 'mother',
            formatterComponent: (_, row) => (
              <div>{getBullName(row.mother)}</div>
            )
          },
        ],
        filterProps: {
          columns: animalSchema.filterColumns
        }
      }}
    />
  );
}

const mapStateToProps = state => ({
  isLoading: state.animals.listIsLoading,
  rows: state.animals.data,
  total: state.animals.totalData,
});

export default connect(mapStateToProps)(List);