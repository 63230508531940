import React, {useMemo, forwardRef} from 'react';
import { Link } from 'react-router-dom';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronDown from '@material-ui/icons/KeyboardArrowDown';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import { ListItem } from './styles';

export default function ListItemLink({
  icon: ItemIcon,
  name,
  selected,
  isCollapsible,
  to,
  isCollapsedItem,
  collapseIsOpen,
  onClick,
  external
}) {
  const renderLink = useMemo(
    () =>
      forwardRef( (linkProps, ref) => {
        if (external) {
          return <a ref={ref} href={to} rel="noreferrer" target="_blank" {...linkProps} />
        }
        return <Link ref={ref} to={to} {...linkProps} external={external ? to : undefined} />
      })
    ,
    [to]
  )

  function itemClick() {
    onClick && onClick(name);
  }

  const colapseProps = isCollapsible ? {} : { component: renderLink };

  return (
    <li>
      <ListItem
        button
        selected={selected}
        onClick={isCollapsible ? itemClick : undefined}
        isCollapsedItem={isCollapsedItem}
        {...colapseProps}
      >
        <ListItemIcon>
          <ItemIcon htmlColor="#FFFFFF" style={{color: '#FFFFFF'}}/>
        </ListItemIcon>
        <ListItemText style={{color: '#FFFFFF'}} primary={name} />

        {isCollapsible && (
          collapseIsOpen
            ? <ChevronDown htmlColor="#FFFFFF" />
            : <ChevronRight htmlColor="#FFFFFF" />
        )}
      </ListItem>
    </li>
  );
}
