import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '~/components/Button';
import useStyles from './styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useDispatch, connect } from 'react-redux';
import { formToggleSaveAndContinue } from '~/store/modules/crud'

function CrudFixedFooterActions({
  isLoading,
  showSaveAndContinue,
  onCancel,
  cancelRoute,
  cancelText,
  submitText,
  onSubmit,
  history,

  isSaving,
  saveAndContinue
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleCancel = useCallback(
    () => {
      if(typeof onCancel === 'function'){
        return onCancel();
      }

      if (cancelRoute){
        return history.push(cancelRoute)
      }

      history.goBack();
    },
    [onCancel, cancelRoute],
  )

  return (
    <div>
      <footer className={clsx('no-printable', classes.footerDrawerClosed)}>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-end"
        >
          {showSaveAndContinue && (
            <FormControlLabel
              className={classes.checkBox}
              control={(
                <Checkbox
                  checked={saveAndContinue}
                  onChange={() => dispatch( formToggleSaveAndContinue() )}
                  color="primary"
                />
              )}
              label="Salvar e continuar"
            />
          )}

          <Button
            onClick={handleCancel}
            className={classes.button}
            color="grey"
          >
            {cancelText}
          </Button>

          <Button
            disabled={isLoading || isSaving}
            onClick={onSubmit}
            className={classes.button}
            color="success"
            type="submit"
          >
            {submitText}
          </Button>
        </Grid>
      </footer>
    </div>
  );
}

CrudFixedFooterActions.defaultProps = {
  isLoading: false,
  showSaveAndContinue: false,
  submitText: 'Salvar',
  cancelText: 'Cancelar',
};

CrudFixedFooterActions.propTypes = {
  cancelRoute: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  showSaveAndContinue: PropTypes.bool,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
};

const mapStateToProps = state => ({
  isSaving: state.crud.form.isSaving,
  saveAndContinue: state.crud.form.saveAndContinue,
});

export default connect( mapStateToProps )( withRouter(CrudFixedFooterActions) );
