import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';

export const Header = styled.header`
  min-height: 160px;
  border-bottom: 1px #907c7c;
  margin-bottom: 20px;
  border-bottom-style: dashed;
  padding-bottom: 20px;
  a {
    text-decoration: none
  }
`;

const imageSize = 160
export const Image = styled(Avatar)`
  height: ${imageSize}px;
  width:  auto;
  align-self: center;
  padding: 2px;
  img {
    object-fit: contain
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 10px;
  grid-template-areas: 'img-dnaGenetica img-cellere img-dinareading';
  min-height: ${imageSize}px;
`;