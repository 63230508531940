import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import InputControl from '~/components/Input/InputControl';
import InputFile from '~/components/Input/InputFile';
import InputSelectAsync from '~/components/Input/InputSelectAsync';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';

import DataImportRecord from '~/records/DataImportRecord';
import { farmAsyncFetch } from '~/services/selectFetch/farmsFetch';

const attrName = (attr) => DataImportRecord.hummanAttributeName(attr);

export default function FormFields({ record, onChange}) {
  return (
    <Card>
      <CardContent>
        <GridContainer>
          <Col sm={4}>
            <InputControl
              required
              name="farm"
              label={attrName('farm')}
              value={record.get('farm')}
              inputComponent={InputSelectAsync}
              onChange={onChange}
              InputProps={{ handleFetch: farmAsyncFetch }}
            />
          </Col>
          <Col xs={8}>
            <InputControl
              required
              name="spreadsheet"
              label={attrName('spreadsheet')}
              value={record.get('spreadsheet')}
              inputComponent={InputFile}
              onChange={onChange}
              InputProps={{
                options: {
                  accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-exce'
                }
              }}
            />
          </Col>
        </GridContainer>
      </CardContent>
    </Card>
  );
}