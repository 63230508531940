import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import GridContainer from '~/components/GridContainer';
import Col from '~/components/Col';

import BaseLayout from '~/components/BaseLayout';

import cellere from '~/assets/images/cellere-black.png'
import dinareading from '~/assets/images/dinareading.png'
import dnaGenetica from '~/assets/images/dna-genetica.png'

const useStyles = makeStyles({
  card: {
    padding: 20,
  },
  mediaContainer: {
    textAlign: 'center'
  },
  mediaImg: {
    width: '100%'
  },
  mediaImgSM6: {
    height: 450,
    width: 'auto',
    maxWidth: '100%'
  }
});

export default function Dashboard() {
  const classes = useStyles();

  return (
    <BaseLayout showDefaultBreadcrumb={false}>
      <GridContainer>
        <Col sm={12}>
          <Card className={classes.card}>
            <div className={classes.mediaContainer}>
              <img
                src={cellere}
                title="Céllere 21"
                className={classes.mediaImg}
              />
            </div>
          </Card>
        </Col>
        <Col sm={12} md={6}>
          <Card className={classes.card}>
            <div className={classes.mediaContainer}>
              <img
                src={dnaGenetica}
                title="DNA Genética do Brasil"
                className={classes.mediaImgSM6}
              />
            </div>
          </Card>
        </Col>
        <Col sm={12} md={6}>
          <Card className={classes.card}>
            <div className={classes.mediaContainer}>
              <img
                src={dinareading}
                title="Dinareading"
                className={classes.mediaImgSM6}
              />
            </div>
          </Card>
        </Col>
      </GridContainer>
    </BaseLayout>
  );
}