import React from 'react';
import { Switch, Route } from 'react-router-dom';

import drawerMenuItems, { drawerMenuAvatar } from '~/routes/drawerMenu';

import DefaultOriginRouteComponent from '~/routes/DefaultOriginRoute'

import { httpStatusRoute } from '~/routes'
import NotFoundComponent from '~/pages/NotFound';

import { dashboardRoute } from '~/routes'
import DashboardComponent from '~/pages/Dashboard';

import { animalsRoute } from '~/routes'
import AnimalsComponent from '~/pages/Animals';

import { metadataRoute } from '~/routes'
import MetadataComponent from '~/pages/Metadata';

import { fieldsRoute } from '~/routes'
import FieldsComponent from '~/pages/Fields';

import { customRecordsRoute } from '~/routes'
import CustomRecordsComponent from '~/pages/CustomRecords';

import { usersRoute } from '~/routes'
import UsersComponent from '~/pages/Users';

import { rolesRoute } from '~/routes'
import RolesComponent from '~/pages/Roles';

import { dataImportsRoute } from '~/routes'
import DataImportsComponent from '~/pages/DataImports';

import { calcSettingsRoute } from '~/routes'
import CalcSettingsComponent from '~/pages/CalcSettings';

import { indicatorWeightsRoute } from '~/routes'
import IndicatorWeightsComponent from '~/pages/IndicatorWeights';

import { rankingsRoute } from '~/routes'
import RankingsComponent from '~/pages/Rankings';

// import ExamplesComponent from '~/pages/Examples';

import DrawerMenu from '~/components/DrawerMenu';
import ProfileMenu from '~/components/ProfileMenu';

import { farmsRoute } from '~/routes'
import FarmsComponent from '~/pages/Farms';

import { PermissionsProvider } from './hooks/usePermissions';
import { MetadataProvider } from './hooks/useMetadata';
import { ProfileDrawerProvider } from './contexts/ProfileDrawerContext';
import { DrawerMenuProvider } from './contexts/DrawerMenuContext';
import { BreadcrumbsProvider } from './hooks/useBreadcrumbs';

function AppRoutes() {
  return (
    <Switch>
      <Route path={animalsRoute.list.path} component={AnimalsComponent} />
      <Route path={customRecordsRoute.list.path} component={CustomRecordsComponent} />
      <Route path={rolesRoute.list.path} component={RolesComponent} />
      <Route path={usersRoute.list.path} component={UsersComponent} />
      <Route path={farmsRoute.list.path} component={FarmsComponent} />

      <Route path={dashboardRoute.self.path} component={DashboardComponent} exact />

      <Route path={dataImportsRoute.list.path} component={DataImportsComponent} />
      <Route path={calcSettingsRoute.self.path} component={CalcSettingsComponent} />
      <Route path={indicatorWeightsRoute.self.path} component={IndicatorWeightsComponent} />
      <Route path={rankingsRoute.index.path} component={RankingsComponent} />

      {/* MASTER */}
      <Route path={metadataRoute.list.path} component={MetadataComponent} />
      <Route path={fieldsRoute.list.path} component={FieldsComponent} />
      {/*<Route path={'/sys/components-example'} component={ExamplesComponent} exact />*/}

      <Route path={httpStatusRoute.notFound.path} component={NotFoundComponent} />
      <Route component={DefaultOriginRouteComponent} />
    </Switch>
  );
}

export default function PrivateRoutes() {
  return(
    <DrawerMenuProvider>
      <BreadcrumbsProvider>
        <PermissionsProvider>
          <MetadataProvider>
            <ProfileDrawerProvider>
              <DrawerMenu avatar={drawerMenuAvatar} items={drawerMenuItems} />
              <ProfileMenu />
              <AppRoutes />
            </ProfileDrawerProvider>
          </MetadataProvider>
        </PermissionsProvider>
      </BreadcrumbsProvider>
    </DrawerMenuProvider>
  );
}