import React, {useState, forwardRef, useMemo}     from 'react';
import PropTypes from 'prop-types';
import cx        from 'classnames';

// @material-ui/core components
import withStyles       from '@material-ui/core/styles/withStyles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Dialog           from '@material-ui/core/Dialog';
import DialogActions    from '@material-ui/core/DialogActions';
import DialogContent    from '@material-ui/core/DialogContent';
import Slide            from '@material-ui/core/Slide';
import AppBar           from '@material-ui/core/AppBar';
import Toolbar          from '@material-ui/core/Toolbar';
import IconButton       from '@material-ui/core/IconButton';
import Typography       from '@material-ui/core/Typography';
import ThemeProvider from '@material-ui/styles/ThemeProvider';

// @material-ui icons
import CloseIcon          from '@material-ui/icons/Close';
import FullscreenIcon     from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

import makeTheme from '~/assets/styles/makePrimaryColorTheme';

const styles = () => ({
  toolbar: {
    display:        'flex',
    justifyContent: 'space-between',
    alignItems:     'center',
    minHeight:      48,
    paddingLeft:    '16px',
    paddingRight:   '16px',
  },
  toolbarText: {
    flex: 1,
    marginRight: '8px'
  },
  toolbarTextWithIcon: {
    marginLeft: '8px'
  },
  toolbarActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialogContentRoot: {
    padding: '16px 16px 8px 16px'
  },
  dialogActions: {
    borderTop: '1px solid rgba(158, 155, 155, 0.1)',
    backgroundColor: 'rgba(193, 190, 190, 0.15)',
    margin: 0,
    padding: '8px 4px',
  },
  dialogPaperScrollPaper: {
    maxHeight: 'calc(100% - 50px)',
  }
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogAppBar = ({color, ...props}) => {
  const theme = useMemo(() => makeTheme(color));
  return <ThemeProvider theme={theme}><AppBar {...props} color='primary'/></ThemeProvider>
}

function CustomDialog({
  title,
  classes,
  icon,
  handleClose,
  children,
  dialogActions,
  dropDownProps,
  isFullScreen: isFullScreenProps,
  fullScreen: isMobile,
  isOpen,
  notFullScreen,
  color,
  ...rest
}) {
  const [isFullScreen, setIsFullScreen] = useState(isFullScreenProps);


  function handleFullScreen() {
    setIsFullScreen(!isFullScreen)
  }

  const isFullScreenActived = isFullScreen || isMobile;

  return(
    <Dialog
      classes={{
        paperScrollPaper: cx({[classes.dialogPaperScrollPaper]: !isFullScreenActived})
      }}
      fullScreen={isFullScreenActived}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      {...rest}
    >
      <DialogAppBar position="static" component='div' color={color}>
        <Toolbar className={classes.toolbar}>
          {icon}
          <Typography variant="h6" color="inherit" className={cx(
            classes.toolbarText,
            {
              [classes.toolbarTextWithIcon]: Boolean(icon)
            }
          )}>
            {title}
          </Typography>
          <div className={classes.toolbarActions}>
            { !notFullScreen && !isMobile && !isFullScreenProps &&
              <IconButton color="inherit" onClick={handleFullScreen} aria-label="FullScreen">
                { isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon /> }
              </IconButton>
            }
            { dropDownProps &&
                { dropDownProps }
            }
            <IconButton color="inherit" onClick={handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </div>
        </Toolbar>
      </DialogAppBar>
      <DialogContent classes={{root: classes.dialogContentRoot}}>
        {children}
      </DialogContent>
      {dialogActions && <DialogActions className={classes.dialogActions}>{dialogActions}</DialogActions> }
    </Dialog>
  );
}

CustomDialog.defaultProps = {
  isOpen:       false,
  isFullScreen: false,
  notFullScreen: false,
  TransitionComponent: Transition,
  maxWidth: 'md',
  color: 'primary',
};

CustomDialog.propTypes = {
  classes:        PropTypes.object.isRequired,
  handleClose:    PropTypes.func.isRequired,
  title:          PropTypes.node.isRequired,
  children:       PropTypes.node.isRequired,
  fullScreen:     PropTypes.bool.isRequired, // withMobileDialog
  // muiClasses:    PropTypes.object,
  isOpen:         PropTypes.bool,
  icon:           PropTypes.node,
  dialogActions:  PropTypes.node, // bottom actions
  dropDownProps:  PropTypes.node, // menu drop actions
  isFullScreen:   PropTypes.bool, // force fullScreen
  notFullScreen:  PropTypes.bool, // force no fullScreen
  color:          PropTypes.string, // force no fullScreen
};

export default withStyles(styles)(
  withMobileDialog()(CustomDialog)
);
