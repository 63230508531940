import SchemaBuilder from './SchemaBuilder';

const hiddenList = {tableView: false, hidden: true, isFilter:  false}

const columns = {
  id:          { type: 'integer',  title: 'ID' },
  farm:        { type: 'object',   width: 200, title: 'Fazenda' },
  user:        { type: 'object',   title: 'Usuário'},
  created_at:  { type: 'datetime', default: '', title: 'Criado em' },
  updated_at:  { type: 'datetime', default: '', title: 'Atualizado em', hidden: true, },
  spreadsheet: { type: 'file',  ...hiddenList, title: 'Planilha'},
  done_at:    { type: 'datetime', default: '', title: 'Processado em', width: 200 },
};

const dataImportSchema = new SchemaBuilder( columns );

export default dataImportSchema;