import SchemaBuilder from './SchemaBuilder';
import genderEnum from '~/enums/genderEnum';

const hiddenList = {tableView: false, hidden: true, isFilter:  false}
const hiddenDefault = {hidden: true}

const columns = {
  id:            { type: 'integer',  width: 80,  default: null, title: 'ID' },
  code:          { type: 'string',   width: 100, title: 'Código'},
  breed:         { type: 'string',   width: 100, title: 'Raça'},
  name:          { type: 'string',   width: 200, default: '',   title: 'Nome' },
  farm:          { type: 'object',   width: 200, title: 'Fazenda' },
  gender:        {
    type: 'enum',
    width: 200,
    default: 'male',
    title: 'Sexo',
    _enum_: genderEnum
  },
  father_code:                    {type: 'string',   width: 170, title: 'Código do pai', ...hiddenDefault},
  father:                         {type: 'object',   width: 220, title: 'Pai', ...hiddenList},
  mother_code:                    {type: 'string',   width: 200, title: 'Código da mãe', ...hiddenDefault},
  mother:                         {type: 'object',   width: 220, title: 'Mãe', ...hiddenList},
  ipg_final:                      {type: 'float',    width: 80,  title: 'IPG'},
  udder_posterior_ligament:       {type: 'float',    width: 180, title: 'Ligamento Post. Úbere', ...hiddenDefault},
  udder_anterior_ligament:        {type: 'float',    width: 180, title: 'Ligamento Ant. Úbere', ...hiddenDefault},
  udder_depth:                    {type: 'float',    width: 180, title: 'Profundidade Úbere', ...hiddenDefault},
  udder_width:                    {type: 'float',    width: 180, title: 'Largura Úbere', ...hiddenDefault},
  udder_height:                   {type: 'float',    width: 180, title: 'Altura Úbere', ...hiddenDefault},
  udder_posterior_tits:           {type: 'float',    width: 180, title: 'Colocação Tetos Posteriores', ...hiddenDefault},
  udder_anterior_tits:            {type: 'float',    width: 180, title: 'Colocação Tetos Anteriores', ...hiddenDefault},
  udder_total:                    {type: 'float',    width: 180, title: 'Cálculo Úbere', ...hiddenDefault},
  solid_compound_fat_vol:         {type: 'float',    width: 180, title: 'Volume de gordura', ...hiddenDefault},
  solid_compound_protein_vol:     {type: 'float',    width: 180, title: 'Volume de proteína', ...hiddenDefault},
  solid_compound_fat_percent:     {type: 'float',    width: 180, title: 'Percentual de gordura', ...hiddenDefault},
  solid_compound_protein_percent: {type: 'float',    width: 180, title: 'Percentual de proteína', ...hiddenDefault},
  solid_compound_somatic_cells:   {type: 'float',    width: 180, title: 'Células Somáticas', ...hiddenDefault},
  solid_compound_total:           {type: 'float',    width: 180, title: 'Cálculo composto de sólidos', ...hiddenDefault},
  fertility_idx_ccr:              {type: 'float',    width: 180, title: 'CCR (Taxa de Prenhes Vacas)', ...hiddenDefault},
  fertility_idx_hcr:              {type: 'float',    width: 180, title: 'HCR (Taxa de Prenhes Novilhas)', ...hiddenDefault},
  fertility_idx_dpr:              {type: 'float',    width: 180, title: 'DPR (Taxa de Prenhes Filhas)', ...hiddenDefault},
  fertility_idx_total:            {type: 'float',    width: 180, title: 'Cálculo de fertilidade', ...hiddenDefault},
  milk_vol:                       {type: 'float',    width: 180, title: 'Volume de leite', ...hiddenDefault},
  composite_paws_legs_side_view:   {type: 'float',    width: 180, title: 'Pernas Vista Lateral', ...hiddenDefault},
  composite_paws_legs_behind_view: {type: 'float',    width: 180, title: 'Pernas Vistas por Trás', ...hiddenDefault},
  composite_paws_legs_hull_angle:  {type: 'float',    width: 180, title: 'Ângulo de Cascos', ...hiddenDefault},
  composite_paws_legs_total:       {type: 'float',    width: 180, title: 'Cálculo composto de patas e pernas', ...hiddenDefault},
  body_compound_stature:           {type: 'float',    width: 180, title: 'Estatura', ...hiddenDefault},
  body_compound_strength:          {type: 'float',    width: 180, title: 'Força', ...hiddenDefault},
  body_compound_depth:             {type: 'float',    width: 180, title: 'Prof. Corporal', ...hiddenDefault},
  body_compound_milk:              {type: 'float',    width: 180, title: 'Caracter Leiteiro', ...hiddenDefault},
  body_compound_croup_angle:       {type: 'float',    width: 180, title: 'Ângulo de Garupa', ...hiddenDefault},
  body_compound_croup_width:       {type: 'float',    width: 180, title: 'Largura de Garupa', ...hiddenDefault},
  body_compound_total:             {type: 'float',    width: 180, title: 'Cálculo composto corporal', ...hiddenDefault},
  created_at:                     {type: 'datetime', width: 180, title: 'Criado em', ...hiddenDefault},
  updated_at:                     {type: 'datetime', width: 180, title: 'Atualizado em'},
};

const animalSchema = new SchemaBuilder( columns );

export default animalSchema;